import React from 'react';
import { useSelector } from 'react-redux';

import CollectionCard from '../../../Collections/CollectionCard';
import { isCollectionItemNew, isEqual } from '../../../common/utils';
import { selectCollectionsByMenu } from '../../../ui/UiSelectors';
import { MenuOption } from './MenuOption';

const MediaCollectionsColumn = ({ selectedMenu }: { selectedMenu: string }) => {
  const collections = useSelector(
    selectCollectionsByMenu(selectedMenu),
    (a, b) => {
      const aIds = a.map(({ id }) => id);
      const bIds = b.map(({ id }) => id);

      return isEqual(aIds, bIds);
    }
  );

  return (
    <>
      <div className="leading-5">
        <MenuOption
          name="Collections"
          className="option font-bold no-underline"
          childClassName="py-2 font-bold"
          showChevron={false}
        />
      </div>
      <div
        className="grid space-y-1 grid-rows-2 px-5 pb-5"
        role="listbox"
        data-cy="media-collections"
      >
        {collections.map((collection) => {
          const {
            id,
            contentClasses,
            view: { heroTitle, routeUri, directoryBackgroundImageUrl },
          } = collection;

          return (
            <CollectionCard
              key={id}
              label={heroTitle}
              href={routeUri}
              thumbnailSrc={directoryBackgroundImageUrl}
              thumbnailAlt={directoryBackgroundImageUrl}
              labelClassName="text-xs px-2 pb-2.5 pt-1.5"
              iconContentClasses={contentClasses}
              showNewLabel={isCollectionItemNew(collection)}
              newLabelClassName="top-2 left-2"
              imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
            />
          );
        })}
      </div>
    </>
  );
};

export default React.memo(MediaCollectionsColumn);
