import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import { setAudioFiltersChanged } from '../../../Audio/actions/AudioActions';
import { searchOrigins } from '../../../app/Navigation/constants';
import { updateSearchOptionsAndRefresh } from '../../../app/Search/actions/SearchActions';
import { StockItemArtistInterface } from '../../../app/Search/containers/MenuContainerInterfaces';
import SearchOptions from '../../../app/Search/entities/SearchOptions';
import { selectSearchFilterOptions } from '../../../app/Search/selectors/searchSelectors';
import AudioSiteConstants from '../../SiteConstants/AudioSiteConstants';
import { StockItem } from '../../types/StockItemTypes';
import { getArtistFullName } from '../../utils';

interface AudioPlayerArtistsProps {
  stockItemArtists?: StockItemArtistInterface[];
  stockItem?: StockItem;
  context?: string;
  className?: string;
  wrapperClassName?: string;
}

const AudioPlayerArtists = ({
  stockItemArtists,
  stockItem,
  context,
  className,
  wrapperClassName,
}: AudioPlayerArtistsProps): JSX.Element => {
  const dispatch = useDispatch();

  const contentType = stockItem.contentType;
  const selectedSearchFilterOptions = useSelector(selectSearchFilterOptions);

  if (contentType !== 'music') {
    return null;
  }

  const onArtistClick = (e, artist) => {
    Telemetry.increment('search.click.artistSearch');

    if (!['search', 'globalPlayer'].includes(context)) {
      return;
    }

    e.preventDefault();

    let { artists } = selectedSearchFilterOptions;

    if (
      artists
        .map((artist) => artist.contributorPortalId)
        .includes(artist.contributorPortalId)
    ) {
      return;
    }

    artists = [...artists, artist];

    let newSearchOptions = SearchOptions.getDefaultSearchOptions();

    newSearchOptions = newSearchOptions.update({
      contentType: selectedSearchFilterOptions.contentType,
      searchOrigin: searchOrigins.ARTIST,
      artists,
    });

    dispatch(setAudioFiltersChanged(true));
    dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };

  return (
    <div
      className={`artist-wrapper w-full whitespace-nowrap overflow-ellipsis overflow-hidden ${wrapperClassName}`}
    >
      {stockItemArtists.map((artist, i) => {
        return (
          <span key={artist.contributorPortalId}>
            {i > 0 && <span className={className}>, </span>}
            <a
              href={`${new AudioSiteConstants().getSearchRoute()}?media-type=${contentType}&portal_artist_ids=${
                artist.contributorPortalId
              }`}
              className={`artist hover:underline mb-1 ${className}`}
              onClick={(e) => onArtistClick(e, artist)}
            >
              {artist.alias || getArtistFullName(artist)}
            </a>
          </span>
        );
      })}
    </div>
  );
};

export default AudioPlayerArtists;
