import React from 'react';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import Carousel, {
  CarouselPropTypes,
} from '../common/components/Carousel/Carousel';
import CarouselItem from '../common/components/Carousel/CarouselItem';
import CollectionCard, { CollectionCardProps } from './CollectionCard';

interface VideoCollectionsCarousel extends Omit<CarouselPropTypes, 'children'> {
  collectionCards: CollectionCardProps[];
}

const VideoCollectionsCarousel = ({
  collectionCards,
  numberItemsToShow = 3,
}: VideoCollectionsCarousel) => {
  return (
    <Carousel numberItemsToShow={numberItemsToShow}>
      {collectionCards.map((collectionCard, idx) => {
        const href = collectionCard.href || '';
        const hrefArr = href.split('/');
        const slug = hrefArr.length > 0 ? hrefArr[hrefArr.length - 1] : '';

        return (
          <CarouselItem className="px-4" key={idx}>
            <CollectionCard
              {...collectionCard}
              onClick={() =>
                Telemetry.increment(`carousel.clicked.collections.${slug}`)
              }
              imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
            />
          </CarouselItem>
        );
      })}
    </Carousel>
  );
};

export default VideoCollectionsCarousel;
