import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DownloadBottom as DownloadIcon,
  SearchSimilar,
} from '@videoblocks/react-icons';
import { Popper, Placements } from '@videoblocks/storywind';

import { deleteFolderContent } from '../../../../../Member/MemberBin/FolderActions';
import { selectMemberBins } from '../../../../../Member/MemberBins/selectors/foldersSelectors';
import {
  selectHasVideoSubscription,
  selectIsLoggedIn,
} from '../../../../../auth/AuthSelectors';
import { default as FavoriteButtonIcon } from '../../../../../common/components/FavoriteButton';
import SignUpForMakerPopover from '../../../../../common/components/Popover/SignUpForMakerPopover';
import {
  convertStockItemContextToOriginArea,
  isStockItemInAnyFolder,
} from '../../../../../common/utils';
import { DrawerType } from '../../../SearchTypes';
import {
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectDrawerType,
} from '../../../selectors/searchSelectors';
import ArrowIcon from '../../filters/ArrowIcon';
import RecommendedMusicIcon from '../RecommendedMusicIcon';
import SimilarVideosIcon from '../SimilarVideosIcon';
import { VideoStockItemCardProps } from '../VideoStockItemCard';

// all buttons extracted from VideoStockItemCard
export const DownloadButton = (
  props: Pick<
    VideoStockItemCardProps,
    'shouldShowDownloadButton' | 'downloadButtonTelemetryAction'
  > & {
    onClick: (evt: React.SyntheticEvent) => void;
    thumbnailExperiment?: boolean;
  }
) => {
  const { shouldShowDownloadButton, downloadButtonTelemetryAction } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const videoSubscription = useSelector(selectHasVideoSubscription);

  const isASubscriptionlessUser = isLoggedIn && !videoSubscription;

  if (shouldShowDownloadButton === false) return null;

  const fireDownloadTelemetryActionFromParent = () => {
    downloadButtonTelemetryAction?.();
  };

  const buttonDivClassName = props.thumbnailExperiment
    ? 'text-white bg-gray-900 w-10 h-10 rounded-full p-3 bg-opacity-80 cursor-pointer hover:bg-opacity-90 flex items-center justify-center'
    : 'action-wrapper download-button action-download button-sizing text-white';
  const svgClassName = props.thumbnailExperiment
    ? 'fill-current w-4 h-4'
    : 'action-icon fill-current';

  return (
    <div>
      <Popper
        placement={props.thumbnailExperiment ? Placements.Top : Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            className={buttonDivClassName}
            data-cy="download-button"
            ref={referenceElement}
            onClick={props.onClick}
            onKeyDown={props.onClick}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="download"
            role="button"
          >
            <DownloadIcon
              onClick={fireDownloadTelemetryActionFromParent}
              className={svgClassName}
            />
          </div>
        )}
      >
        <div id="tooltip-download">
          {isASubscriptionlessUser ? 'Download Preview' : 'Download'}
        </div>
      </Popper>
    </div>
  );
};

export const YellowDownloadCTAButton = ({
  disabled = false,
  buttonText = 'Get Unlimited Downloads',
  downloadButtonTelemetryAction,
  onClick,
  ...rest
}) => {
  const fireDownloadTelemetryActionFromParent = () => {
    downloadButtonTelemetryAction?.();
  };

  return (
    <button
      {...rest}
      className="bg-yellow-500 h-full bg-opacity-100 py-2.5 px-3 flex items-center text-white space-x-1.5 rounded-full cursor-pointer border-0 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"
      onClick={onClick}
    >
      <DownloadIcon
        fill="black"
        className="fill-black h-4 w-4"
        onClick={fireDownloadTelemetryActionFromParent}
      />
      <span className="text-sm leading-5 text-black">{buttonText}</span>
    </button>
  );
};

export const FavoriteButton = (
  props: Pick<
    VideoStockItemCardProps,
    | 'stockItem'
    | 'stockItemFormats'
    | 'favoriteButtonTelemetryAction'
    | 'containPoppers'
    | 'openFoldersInNewWindow'
    | 'context'
  > & { onClick: (e: MouseEvent) => void; thumbnailExperiment?: boolean }
) => {
  const memberBins = useSelector(selectMemberBins);
  const bins = memberBins ? Object.values(memberBins) : [];
  const [showTooltip, setShowTooltip] = React.useState(false);

  const tooltipText = isStockItemInAnyFolder(bins, props.stockItem)
    ? 'Added to Folder'
    : 'Add to Folder';

  const buttonDivClassName = 'action-wrapper button-sizing';
  const svgClassName = props.thumbnailExperiment ? 'w-4 h-4' : 'p-1.5 w-5 h-5';
  const buttonClassName = props.thumbnailExperiment
    ? 'text-white bg-gray-900 w-10 h-10 rounded-full p-3 bg-opacity-80 hover:bg-opacity-90 cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white'
    : '';
  const fireFavoriteButtonTelemetryFromParent = () => {
    props.favoriteButtonTelemetryAction?.();
  };

  const originArea = convertStockItemContextToOriginArea(props.context);

  return (
    <div>
      <Popper
        placement={props.thumbnailExperiment ? Placements.Top : Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            className={buttonDivClassName}
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
          >
            <FavoriteButtonIcon
              onButtonClick={() => {
                fireFavoriteButtonTelemetryFromParent();
                setShowTooltip(false);
              }}
              onClickAction={props.onClick}
              stockItem={props.stockItem}
              stockItemFormats={props.stockItemFormats}
              telemetryKey="search"
              iconClassName={svgClassName}
              containPoppers={props.containPoppers}
              openFoldersInNewWindow={props.openFoldersInNewWindow}
              originArea={originArea}
              buttonClasses={buttonClassName}
            />
          </div>
        )}
      >
        <div id="tooltip-favorite" className="whitespace-nowrap">
          {tooltipText}
        </div>
      </Popper>
    </div>
  );
};

export const DeleteButton = (
  props: Pick<
    VideoStockItemCardProps,
    'shouldShowDeleteButton' | 'binUniqueId' | 'stockItem'
  >
) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const dispatch = useDispatch();

  if (props.shouldShowDeleteButton === false) return null;

  const deleteFromFolder = () => {
    // @ts-ignore
    dispatch(deleteFolderContent(props.binUniqueId, [props.stockItem]));
  };

  return (
    <div>
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            className="action-wrapper download-button action-download button-sizing"
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="delete from folder"
            role="button"
          >
            <div className="action-wrapper">
              <img
                src={`${__ASSETS_COMMON_IMAGES_URL__}/member/folders/trash-with-padding.svg`}
                alt="Delete from folder icon"
                className="stockItemCard-deleteButton action-icon"
                onClick={deleteFromFolder}
                onKeyDown={deleteFromFolder}
              />
            </div>
          </div>
        )}
      >
        <div id="tooltip-delete">Delete from Folder</div>
      </Popper>
    </div>
  );
};

export const MakerButton = (
  props: Pick<
    VideoStockItemCardProps,
    'stockItem' | 'makerButtonTelemetryAction'
  > & { thumbnailExperiment?: boolean }
) => {
  const buttonDivClassName = props.thumbnailExperiment
    ? 'text-white bg-gray-900 w-10 h-10 rounded-full p-3 bg-opacity-80 hover:bg-opacity-90 cursor-pointer'
    : '';
  const iconClassName = props.thumbnailExperiment
    ? 'fill-current w-4 h-4'
    : 'fill-current text-white w-10 h-10';

  return (
    <SignUpForMakerPopover
      mediaType="video"
      className={buttonDivClassName}
      iconClassName={iconClassName}
      stockItemId={props.stockItem.id}
      onClickAction={() => props.makerButtonTelemetryAction?.()}
      placement={props.thumbnailExperiment ? Placements.Top : Placements.Left}
    />
  );
};

export const SimilarButton = (
  props: Pick<VideoStockItemCardProps, 'stockItem'> & {
    onClick: () => void;
    thumbnailExperiment?: boolean;
  }
) => {
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const drawerSelectedItemId = useSelector(selectDrawerSelectedItemId);
  const drawerType = useSelector(selectDrawerType);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const isSimilarVideosDrawerOpen = () =>
    [
      drawerSelectedItemId === props.stockItem.id,
      drawerType === DrawerType.MORE_LIKE_THIS,
      drawerIsOpen,
    ].every(Boolean);

  return (
    <div className="max-w-11.5">
      <Popper
        placement={Placements.Top}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            role="button"
            aria-label="More like this"
            tabIndex={0}
          >
            <div
              className="action-wrapper similar-button"
              role="button"
              tabIndex={0}
              onClick={props.onClick}
              onKeyDown={props.onClick}
            >
              <SimilarVideosIcon
                className="action-icon p-1"
                width={38}
                height={20}
                state={isSimilarVideosDrawerOpen() ? 'opened' : 'closed'}
              />
            </div>
          </div>
        )}
      >
        <div className="whitespace-nowrap" id="tooltip-similar">
          More Like This
        </div>
      </Popper>
    </div>
  );
};

export const RecommendedMusicButton = (
  props: Pick<VideoStockItemCardProps, 'stockItem'> & { onClick: () => void }
) => {
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const drawerSelectedItemId = useSelector(selectDrawerSelectedItemId);
  const drawerType = useSelector(selectDrawerType);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const isRecommendedMusicrawerOpen = () =>
    [
      drawerSelectedItemId === props.stockItem.id,
      drawerType === DrawerType.RECOMMENDED_MUSIC,
      drawerIsOpen,
    ].every(Boolean);

  return (
    <div className="max-w-11.5">
      <Popper
        placement={Placements.Top}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            tabIndex={0}
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            aria-label="Recommended Music"
            role="button"
          >
            <div
              role="button"
              tabIndex={0}
              className="action-wrapper recommended-music-button"
              onClick={props.onClick}
              onKeyDown={props.onClick}
            >
              <RecommendedMusicIcon
                className="action-icon p-1"
                width={38}
                height={20}
                state={isRecommendedMusicrawerOpen() ? 'opened' : 'closed'}
              />
            </div>
          </div>
        )}
      >
        <div id="tooltip-similar" className="whitespace-nowrap">
          Recommended Music
        </div>
      </Popper>
    </div>
  );
};

interface RelatedVideosButtonProps {
  onMouseEnter?: () => void;
  onFocus?: () => void;
  onMouseLeave?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  isSimilarVideosDrawerOpen?: boolean;
  onlyShowIcon?: boolean;
}

export const RelatedVideoButton = (props: RelatedVideosButtonProps) => {
  const { isSimilarVideosDrawerOpen, onlyShowIcon, ...rest } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Popper
      placement={Placements.Top}
      popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
      showContent={showTooltip && onlyShowIcon}
      triggerElement={(refernceElement) => {
        return (
          <button
            {...rest}
            ref={refernceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onFocus={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            className="bg-gray-900 h-full bg-opacity-80 py-2.5 px-3 flex items-center text-white space-x-1.5 rounded-full cursor-pointer border-0 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"
          >
            <SearchSimilar fill="white" className="fill-white h-4 w-4" />
            {!onlyShowIcon && (
              <>
                <span className="text-sm leading-5">Related Video</span>
                <ArrowIcon
                  isCollapsed={!isSimilarVideosDrawerOpen}
                  invalidSelectionMessage={''}
                  className={'fill-white w-2 h-2'}
                />
              </>
            )}
          </button>
        );
      }}
    >
      {onlyShowIcon && (
        <div className="whitespace-nowrap" id="tooltip-related-search">
          Related Video
        </div>
      )}
    </Popper>
  );
};

export const RelatedVideoButtonIcon = (props: RelatedVideosButtonProps) => {
  const { isSimilarVideosDrawerOpen, ...rest } = props;
  return (
    <button
      {...rest}
      className="bg-gray-900 bg-opacity-80 py-2.5 px-3 flex items-center text-white space-x-1.5 rounded-full cursor-pointer border-0 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white"
    >
      <SearchSimilar fill="white" className="fill-white h-4 w-4" />
      <span className="text-sm leading-5">Related Video</span>
      <ArrowIcon
        isCollapsed={!isSimilarVideosDrawerOpen}
        invalidSelectionMessage={''}
        className={'fill-white w-2 h-2'}
      />
    </button>
  );
};

export const RelatedVideosButtonContainer = (
  props: Pick<VideoStockItemCardProps, 'stockItem'> & {
    onClick: () => void;
    onlyShowIcon?: boolean;
  }
) => {
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const drawerSelectedItemId = useSelector(selectDrawerSelectedItemId);
  const drawerType = useSelector(selectDrawerType);

  const isSimilarVideosDrawerOpen = () =>
    [
      drawerSelectedItemId === props.stockItem.id,
      drawerType === DrawerType.MORE_LIKE_THIS ||
        drawerType === DrawerType.SAME_SHOOT_SAME_MODEL_SIMILAR_CLIPS,
      drawerIsOpen,
    ].every(Boolean);

  return (
    <div>
      <RelatedVideoButton
        onClick={props.onClick}
        isSimilarVideosDrawerOpen={isSimilarVideosDrawerOpen()}
        onlyShowIcon={props.onlyShowIcon}
      />
    </div>
  );
};
