import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { contentTypes } from '../../../../common/Constants';
import { usePrevious } from '../../../../common/hooks/usePrevious';
import { get, isEqual } from '../../../../common/utils';
import { searchOrigins } from '../../../Navigation/constants';
import {
  updateSearchOptionsAndRefresh,
  updateTotalResultsCountForFilter,
} from '../../actions/SearchActions';
import {
  AudioSelectedSearchFilterOptions,
  SelectedSearchFilterOptions,
} from '../../containers/MenuContainerInterfaces';
import { sfxCategories } from '../../entities/AudioSearchFilterOptions';
import SearchFilterOptions from '../../entities/SearchFilterOptions';
import SearchOptions from '../../entities/SearchOptions';
import {
  selectSearchFilterOptions,
  selectSearchResultsTotalCountsForFilters,
} from '../../selectors/searchSelectors';
import {
  AudioFilterGroupNames,
  FilterInputType,
} from '../menu/common/MenuEnums';
import {
  AudioFiltersByContentType,
  AudioFiltersOptions,
} from './common/FilterUtils';
import MobileFiltersContainer from './common/MobileFiltersContainer';
import MobileCategoriesFilter from './filters/MobileCategoriesFilter';
import MobileDurationFilter from './filters/MobileDurationFilter';
import MobileGenresFilter from './filters/MobileGenresFilter';
import MobileInstrumentsFilter from './filters/MobileInstrumentsFilter';
import MobileMediaTypeFilter from './filters/MobileMediaTypeFilter';
import MobileMoodsFilter from './filters/MobileMoodsFilter';
import MobileTempoFilter from './filters/MobileTempoFilter';
import MobileVocalsFilter from './filters/MobileVocalsFilter';

interface MobileAudioFiltersProps {
  setAppliedFiltersTotalCount: (newAppliedFiltersCount: number) => void;
}

const MobileAudioFilters = ({
  setAppliedFiltersTotalCount,
}: MobileAudioFiltersProps): JSX.Element => {
  const searchResultsTotalCountsForFilters = useSelector(
    selectSearchResultsTotalCountsForFilters
  );
  const selectedSearchFilterOptionsRedux = useSelector(
    selectSearchFilterOptions
  );

  // need to have a local filer states to keep tracks for filters change before updates to global filter
  // state because results won't be show until user press show results
  // and exiting the mobile filter without pressing show results button will reset to the prior filters state
  const [
    selectedSearchFilterOptionsLocal,
    setSelectedSearchFilterOptionsLocal,
  ] = useState<AudioSelectedSearchFilterOptions>({
    ...selectedSearchFilterOptionsRedux,
  });

  const [collapsed, setCollapsed] = useState({
    [AudioFiltersOptions.CONTENT_TYPE]: false,
    [AudioFiltersOptions.SFX_CATEGORIES]: true,
    [AudioFiltersOptions.MUSIC_MOODS]: true,
    [AudioFiltersOptions.MUSIC_GENRES]: true,
    [AudioFiltersOptions.MUSIC_INSTRUMENTS]: true,
    [AudioFiltersOptions.VOCAL_TYPE]: true,
    [AudioFiltersOptions.TEMPO]: true,
    [AudioFiltersOptions.DURATION]: true,
  });

  const toggleCollapsed = (name) => {
    setCollapsed({
      ...collapsed,
      [name]: !collapsed[name],
    });
  };

  const previousSelectedFilterOptionsLocal = usePrevious(
    selectedSearchFilterOptionsLocal
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSearchFilterOptionsRedux) {
      setSelectedSearchFilterOptionsLocal({
        ...selectedSearchFilterOptionsLocal,
      });
      dispatch(
        updateTotalResultsCountForFilter(selectedSearchFilterOptionsRedux)
      );
    }
  }, [selectedSearchFilterOptionsRedux]);

  const debouncedUpdateTotalResultsCountForFilter = useCallback(
    debounce((newSearchOptions: SelectedSearchFilterOptions) => {
      dispatch(updateTotalResultsCountForFilter(newSearchOptions));
    }, 100),
    [dispatch]
  );

  useEffect(() => {
    if (
      previousSelectedFilterOptionsLocal &&
      selectedSearchFilterOptionsLocal &&
      previousSelectedFilterOptionsLocal !== selectedSearchFilterOptionsLocal
    ) {
      const isContentTypeChange =
        get(
          previousSelectedFilterOptionsLocal,
          AudioFiltersOptions.CONTENT_TYPE
        ) !==
        get(selectedSearchFilterOptionsLocal, AudioFiltersOptions.CONTENT_TYPE);
      const newUpdateFields = {
        ...selectedSearchFilterOptionsLocal,
        page: 1,
        isPagination: false,
        searchOrigin: searchOrigins.FILTERS,
        ...(isContentTypeChange && { contributorIds: [] }),
        ...(isContentTypeChange && { similarTo: null }),
        ...(isContentTypeChange && { searchSimilarTitle: null }),
        ...(isContentTypeChange && {
          contentClass: SearchFilterOptions.getContentClassFromContentType(
            selectedSearchFilterOptionsLocal.contentType
          ),
        }),
      };
      const newSearchOptions =
        selectedSearchFilterOptionsRedux.update(newUpdateFields);
      debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
    }
  }, [selectedSearchFilterOptionsLocal, previousSelectedFilterOptionsLocal]);

  useEffect(() => {
    if (selectedSearchFilterOptionsLocal) {
      const defaultSelectedSearchOptions =
        SearchOptions.getDefaultSearchOptions();
      const { contentType } = selectedSearchFilterOptionsLocal;
      const audioFilters = AudioFiltersByContentType[contentType];
      const numberOfAppliedFilters = Object.values(audioFilters).reduce<number>(
        (acc: number, key: string) => {
          if (key === AudioFiltersOptions.DURATION) {
            const { minDuration, maxDuration } =
              selectedSearchFilterOptionsLocal;
            if (minDuration || maxDuration) {
              return acc + 1;
            }
          } else if (key === AudioFiltersOptions.TEMPO) {
            const { tempoMin, tempoMax } = selectedSearchFilterOptionsLocal;
            if (tempoMin || tempoMax) {
              return acc + 1;
            }
          } else if (
            !isEqual(
              selectedSearchFilterOptionsLocal[key],
              defaultSelectedSearchOptions[key]
            )
          ) {
            return acc + 1;
          }
          return acc;
        },
        0
      );
      setAppliedFiltersTotalCount(numberOfAppliedFilters);
    }
  }, [selectedSearchFilterOptionsLocal]);

  const handleCheckboxAndRadioFiltersChange = (name, value) => {
    setSelectedSearchFilterOptionsLocal({
      ...selectedSearchFilterOptionsLocal,
      [name]: value,
    });
  };

  const handleDurationSliderAfterChange = (slider1, slider2) => {
    setSelectedSearchFilterOptionsLocal({
      ...selectedSearchFilterOptionsLocal,
      minDuration: slider1,
      maxDuration: slider2,
    });
  };

  const handleTempoSliderAfterChange = (slider1, slider2) => {
    const newState: AudioSelectedSearchFilterOptions = {
      ...selectedSearchFilterOptionsLocal,
      tempoMin: slider1,
      tempoMax: slider2,
    };
    setSelectedSearchFilterOptionsLocal(newState);
  };

  const invalidSelectionMessage =
    selectedSearchFilterOptionsLocal.contentType !==
      contentTypes.SOUND_EFFECT && 'Select Sound Effects to see Categories';

  const handleClearFilters = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    let newSearchOptions = SearchOptions.getDefaultSearchOptions();

    newSearchOptions = newSearchOptions.update({
      searchTerm: selectedSearchFilterOptionsRedux.searchTerm,
    });

    setSelectedSearchFilterOptionsLocal({
      ...newSearchOptions,
    });

    debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
  };

  const handleShowResult = () => {
    const isContentTypeChange =
      previousSelectedFilterOptionsLocal !== undefined &&
      get(
        previousSelectedFilterOptionsLocal,
        AudioFiltersOptions.CONTENT_TYPE
      ) !==
        get(selectedSearchFilterOptionsLocal, AudioFiltersOptions.CONTENT_TYPE);

    const updateFields = {
      ...selectedSearchFilterOptionsLocal,
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.FILTERS,
      ...(isContentTypeChange && { contributorIds: [] }),
      ...(isContentTypeChange && { similarTo: null }),
      ...(isContentTypeChange && { searchSimilarTitle: null }),
      ...(isContentTypeChange && {
        contentClass: SearchFilterOptions.getContentClassFromContentType(
          selectedSearchFilterOptionsLocal.contentType
        ),
      }),
    };
    const newSearchOptions =
      selectedSearchFilterOptionsRedux.update(updateFields);
    dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };

  return (
    <MobileFiltersContainer
      resultsTotalCount={searchResultsTotalCountsForFilters}
      handleClearAll={handleClearFilters}
      handleShowResult={handleShowResult}
    >
      <div className="flex flex-col">
        <MobileMediaTypeFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.CONTENT_TYPE]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.CONTENT_TYPE)
          }
        />
        <MobileMoodsFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.MUSIC_MOODS]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.MUSIC_MOODS)
          }
          className="order-3"
        />
        <MobileGenresFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.MUSIC_GENRES]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.MUSIC_GENRES)
          }
          className="order-4"
        />
        <MobileInstrumentsFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.MUSIC_INSTRUMENTS]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.MUSIC_INSTRUMENTS)
          }
          className="order-5"
        />
        <MobileVocalsFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.VOCAL_TYPE]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.VOCAL_TYPE)
          }
          className="order-6"
        />
        <MobileTempoFilter
          onAfterChange={handleTempoSliderAfterChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[AudioFiltersOptions.TEMPO]}
          toggleCollapsed={() => toggleCollapsed(AudioFiltersOptions.TEMPO)}
          className="order-7"
        />
        <MobileDurationFilter
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          onAfterChange={handleDurationSliderAfterChange}
          collapsed={collapsed[AudioFiltersOptions.DURATION]}
          toggleCollapsed={() => toggleCollapsed(AudioFiltersOptions.DURATION)}
          className={
            get(
              selectedSearchFilterOptionsLocal,
              AudioFiltersOptions.CONTENT_TYPE
            ) === contentTypes.SOUND_EFFECT
              ? 'order-1'
              : 'order-8'
          }
        />
        <MobileCategoriesFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          allOptions={sfxCategories}
          selectedOptions={selectedSearchFilterOptionsLocal.sfxCategories}
          invalidSelectionMessage={invalidSelectionMessage}
          collapsed={collapsed[AudioFiltersOptions.SFX_CATEGORIES]}
          toggleCollapsed={() =>
            toggleCollapsed(AudioFiltersOptions.SFX_CATEGORIES)
          }
          className={
            get(
              selectedSearchFilterOptionsLocal,
              AudioFiltersOptions.CONTENT_TYPE
            ) === contentTypes.SOUND_EFFECT
              ? 'order-2'
              : 'order-9'
          }
          groupName={AudioFilterGroupNames.SFX_CATEGORIES}
          inputType={FilterInputType.CHECKBOX}
        />
      </div>
    </MobileFiltersContainer>
  );
};

export default MobileAudioFilters;
