import React from 'react';
import { useSelector } from 'react-redux';

import RightSideMenu from '@videoblocks/shared-components/dist/mjs/components/RightSideMenu/RightSideMenu';

import Logger from '../../../common/Logger';
import { BetaBadgeClasses } from '../../../common/components/BetaBadge';
import { selectRightSideNavMenu } from '../../../ui/UiSelectors';
import { LoggerFn } from '../../Shared/types';

const RightSideMenuContainer = () => {
  const rightSideNav = useSelector(selectRightSideNavMenu);

  const logger: LoggerFn = (id) => {
    Logger.accessTelemetry().increment(id);
  };
  const imagesUrl = __ASSETS_COMMON_IMAGES_URL__;

  return (
    <RightSideMenu
      {...{ ...rightSideNav, logger, BetaBadgeClasses, imagesUrl }}
    />
  );
};
export default RightSideMenuContainer;
