import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDown } from '@videoblocks/react-icons';

import { slugify } from '../../../common/utils';
import BusinessMenu from '../components/BusinessMenu';
import CreativeToolsMenu from '../components/CreativeToolsMenu';
import ResourcesMenu from '../components/ResourcesMenu';
import StockMediaMenu from '../components/StockMediaMenu';

function toggleAriaExpanded(element: HTMLElement, value: boolean) {
  element.setAttribute('aria-expanded', String(value));
}

enum DropdownIds {
  StockMedia = 'content',
  CreativeTools = 'creative-tools',
  Businesses = 'businesses',
  Resources = 'resources',
}

type Dropdown = {
  id: DropdownIds;
  title: string;
  menu: React.ReactElement;
};

const NAV_DROPDOWNS: Dropdown[] = [
  {
    id: DropdownIds.StockMedia,
    title: 'Stock Media',
    menu: <StockMediaMenu />,
  },
  {
    id: DropdownIds.CreativeTools,
    title: 'Creative Tools',
    menu: <CreativeToolsMenu />,
  },
  {
    id: DropdownIds.Businesses,
    title: 'Businesses',
    menu: <BusinessMenu />,
  },
  {
    id: DropdownIds.Resources,
    title: 'Resources',
    menu: <ResourcesMenu />,
  },
];

const navClasses =
  'h-20 group relative inline-flex items-center xl:px-4 px-2 md:px-1';

const MainNavContainer = () => (
  <div className="mainNav-siteLinks flex justify-evenly w-full">
    {NAV_DROPDOWNS.map((dropdown) => (
      <div
        className={twMerge(
          `mainNav-${dropdown.id} hover:bg-blue-100`,
          navClasses
        )}
        key={dropdown.id}
        aria-expanded={false}
        role="button"
        tabIndex={0}
        aria-label={dropdown.title}
        onMouseOver={(evt) => toggleAriaExpanded(evt.currentTarget, true)}
        onMouseLeave={(evt) => toggleAriaExpanded(evt.currentTarget, false)}
        onFocus={(evt) => toggleAriaExpanded(evt.currentTarget, true)}
        onBlur={(evt) => toggleAriaExpanded(evt.currentTarget, false)}
        id={`main-nav-${slugify(dropdown.title)}`}
      >
        <span className="flex items-center font-semibold text-gray-900">
          {dropdown.title}
          <ChevronDown className="w-2 h-2 ml-2 group-hover:rotate-180 transition-transform transform fill-black" />
        </span>
        <div
          className={twMerge(
            `${dropdown.id}-menu`,
            'group-hover:visible shadow-popover absolute leading-6 bg-white rounded-xl invisible z-10 pr-px pt-2',
            'top-20 storyboard-lg:-left-3/4 -left-12',
            dropdown.id === DropdownIds.StockMedia ? 'w-288' : 'w-72'
          )}
          id={`${slugify(dropdown.title)}Dropdown`}
        >
          {dropdown.menu}
        </div>
        <div className="bottomBorder bg-blue-900 w-full left-0 bottom-1 xl:bottom-0 absolute invisible group-hover:visible" />
      </div>
    ))}
  </div>
);

export default MainNavContainer;
