import React from 'react';
import { useSelector } from 'react-redux';
import { Reducer } from 'redux';
import { twMerge } from 'tailwind-merge';

import { ArrowUp } from '@videoblocks/react-icons';
import {
  getTopLevelOptions,
  COLLECTIONS,
  TemplateOptions,
} from '@videoblocks/shared-components';

import { selectIsLoggedIn } from '../../../auth/AuthSelectors';
import { titleCase } from '../../../common/utils';
import { selectNavMenu, selectNavMenuCategory } from '../../../ui/UiSelectors';
import { NavCategory } from '../../../ui/UiTypes';
import { audioOptions, videoOptions } from '../constants';
import MediaCollectionsColumn from './MediaCollectionsColumn';
import { MenuOption, MenuOptionProps } from './MenuOption';
import MiddleColumn from './StockMediaMenuMiddleColumn';

// todo: just use/import in MenuOption?
import './StockMediaMenu.less';

type StockMediaMenuState = {
  selectedMenu: string;
  selectedCategory: NavCategory;
  subCategories: NavCategory[];
  showCollections: boolean;
};

const TopLabel = ({
  selectedMenu,
  selectedCategory,
  onClick,
  className,
}: {
  selectedMenu: StockMediaMenuState['selectedMenu'];
  selectedCategory: StockMediaMenuState['selectedCategory'];
  onClick: (object: object) => void;
  className?: string;
}) => {
  const selectedNavMenu = useSelector(selectNavMenuCategory(selectedMenu));
  const categorySelected = !!selectedCategory.title;

  const linkProps = {
    className: 'text-gray-900 flex flex-row cursor-pointer',
    tabIndex: 0,
  };

  if (categorySelected) {
    Object.assign(linkProps, {
      role: 'button',
      onClick: () => onClick({ selectedCategory: '' }),
    });
  } else {
    Object.assign(linkProps, {
      href: selectedNavMenu?.url,
    });
  }

  const iconClassNames = 'fill-current w-3 h-3';

  return (
    <div
      role="button"
      tabIndex={0}
      className={twMerge('md:pt-2 pr-5 pl-5 pb-2', className)}
      data-cy="top-label"
      onClick={(evt) => {
        if (categorySelected) evt.preventDefault();
        (evt.currentTarget.firstChild as HTMLLinkElement).click();
      }}
      onKeyDown={(evt) => {
        if (categorySelected) evt.preventDefault();
        (evt.currentTarget.firstChild as HTMLLinkElement).click();
      }}
    >
      <a {...linkProps}>
        {categorySelected && (
          <span className="float-right mr-2">
            <ArrowUp
              className={twMerge(iconClassNames, 'transform -rotate-90 mt-1')}
            />
          </span>
        )}
        <span className="float-left flex-grow font-bold">
          {categorySelected && 'All '}
          {titleCase(selectedMenu.replace(/-/g, ' '))}
        </span>
      </a>
    </div>
  );
};

const StockMediaMenu = () => {
  /**
   * Overall container has 11 columns. The left (static) is always 3-wide.
   * For video media types, columns are 3–5-3 wide with internal columns.
   * Other media types' "middle" column is 8-wide (full width), contains
   * 3 or 4 columns as needed
   *
   * +-----------------+---------------------------------------+---------------------------+
   * | Video Home      | All Stock Footage                     | Stock Footage Collections |
   * +-----------------+---------------------------------------+---------------------------|
   * | > Stock Footage | Travel                 | Medical      | [Collection 1]            |
   * | Audio Home      | Lifestyle              | Technology   |                           |
   * | > Music         | Places                 | Nature       | [Collection 2]            |
   * | Images Home     | Video Category...      | Vid Category |                           |
   * +-----------------+------------------------+--------------+---------------------------+
   *
   * +-----------------+--------------------------------------------------------------------+
   * | Video Home      | All Royalty Free Music                                             |
   * +-----------------+--------------------------------------------------------------------+
   * | > Stock Footage | Moods           | Genres              |            | Instruments   |
   * | Audio Home      | Dark            | Ambient             | Folk       | Brass         |
   * | > Music         | Epic            | Blues               | Hip Hop    | Guitar        |
   * | Images Home     | Happy           | Bumpers/Stingers    | Holidays   | Orchestra     |
   * +-----------------+-----------------+---------------------+------------+---------------+
   */
  const { top_level_categories } = useSelector(selectNavMenu);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [menuState, updateMenuState] = React.useReducer<
    Reducer<StockMediaMenuState>
  >(
    (prev, next) => {
      const newState = {
        ...prev,
        ...next,
      };

      newState.showCollections = Object.values(videoOptions).includes(
        newState.selectedMenu
      );

      if (next.selectedCategory) {
        const { categories } = top_level_categories[newState.selectedMenu];
        const { url = '', subCategories = [] } =
          categories[next.selectedCategory];

        Object.assign(newState, {
          selectedCategory: {
            title: next.selectedCategory,
            url,
          },
          subCategories,
        });
      } else {
        Object.assign(newState, {
          selectedCategory: {},
          subCategories: [],
        });
      }

      return newState;
    },
    {
      selectedMenu: videoOptions.STOCK_FOOTAGE,
      selectedCategory: {} as NavCategory,
      subCategories: [],
      showCollections: true,
    }
  );
  const { selectedMenu, selectedCategory, showCollections } = menuState;

  const mapMediaType = (mediaType) => {
    const mediaTypeProps: MenuOptionProps = {
      name: mediaType.label,
      className: 'option no-underline navMenu-option',
      childClassName: 'py-2 hover:text-blue-700 hover:bg-transparent',
    };

    if (mediaType.url) {
      mediaTypeProps.href = mediaType.url;
      mediaTypeProps.showChevron = false;
    } else {
      mediaTypeProps.event = 'mouseEnter';
      mediaTypeProps.action = (selectedMenu) =>
        // @ts-ignore
        updateMenuState({ selectedMenu });
    }

    return (
      <React.Fragment key={mediaType.label}>
        {/* todo: don't update menu state if mouse is moving -> and crosses another MenuOption */}
        <MenuOption {...mediaTypeProps} />
        {mediaType.options?.map((subOpt) => (
          <MenuOption
            key={subOpt.label}
            name={subOpt.label}
            className="option"
            childClassName="py-2 pl-10 pr-6 hover:bg-blue-100 hover:text-blue-900"
            href={subOpt.url}
            event="mouseEnter"
            // @ts-ignore
            action={(selectedMenu) => updateMenuState({ selectedMenu })}
          />
        ))}
      </React.Fragment>
    );
  };

  return (
    <div className="grid grid-cols-11 min-h-100">
      {/* left column */}
      <div
        className="col-span-3 pt-2 border-gray-300 border-solid border-r border-l-0 border-t-0 border-b-0"
        data-cy="column-one"
      >
        {/* todo: update server-side props to match */}
        {getTopLevelOptions(isLoggedIn).map(mapMediaType)}
      </div>
      {/* middle column */}
      <div
        className={twMerge(
          'py-2 pb-4',
          showCollections ? 'col-span-5' : 'col-span-8'
        )}
        data-cy="column-two"
      >
        {selectedMenu && (
          <>
            {selectedMenu !== COLLECTIONS && (
              <TopLabel
                selectedMenu={selectedMenu}
                selectedCategory={selectedCategory}
                onClick={updateMenuState}
                className={twMerge(
                  showCollections ? 'w-2/4' : 'w-1/4',
                  selectedMenu === audioOptions.MUSIC && 'pr-3',
                  selectedMenu === audioOptions.EFFECTS && 'pr-12 pl-4',
                  selectedMenu === TemplateOptions.DAVINCI_RESOLVE && 'w-2/4'
                )}
              />
            )}
            <MiddleColumn
              menuState={menuState}
              updateMenuState={updateMenuState}
            />
          </>
        )}
      </div>
      {/* right column */}
      {showCollections && (
        <div
          className="col-span-3 pt-2 pr-2 bg-gray-100 flex flex-col w-full"
          data-cy="column-three"
        >
          <MediaCollectionsColumn selectedMenu={selectedMenu} />
        </div>
      )}
    </div>
  );
};

export default StockMediaMenu;
