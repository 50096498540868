import React, { useState } from 'react';

// todo: move back into stockblocks?
import { HubspotForm, ModalDialog } from '@videoblocks/shared-components';

import ChiliPiperLoader from '../../../common/components/ChiliPiperLoader';

const TalkToSalesButton = ({ className = '' }: { className?: string }) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className={className}>
      <button
        className="text-white font-semibold font-inter bg-blue-600 hover:bg-blue-700 rounded-lg border-none px-8 py-2 my-1 gap-2"
        onClick={() => {
          setOpenForm(true);
        }}
      >
        Talk to Sales
      </button>
      <ModalDialog
        isOpen={openForm}
        onRequestClose={() => {
          setOpenForm(false);
        }}
        topOffsetClass="top-24"
        className="max-w-4xl h-5/6 overflow-scroll lg:px-24"
      >
        <HubspotForm
          formId="76d0e126-0f0e-4161-8cb3-1a7a59245783"
          loadingID="talk-to-sales"
        />
        <ChiliPiperLoader />
      </ModalDialog>
    </div>
  );
};

export default TalkToSalesButton;
