import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { Popper, Placements } from '@videoblocks/storywind';

import { setAudioFiltersChanged } from '../../../Audio/actions/AudioActions';
import { searchOrigins } from '../../../app/Navigation/constants';
import { updateSearchOptionsAndRefresh } from '../../../app/Search/actions/SearchActions';
import AudioSearchOptions from '../../../app/Search/entities/AudioSearchOptions';
import {
  ContentClass,
  SearchFilterContentTypes,
} from '../../../app/Shared/enums';

interface Props {
  stockItemId: number;
  stockItemTitle: string;
  selectedContentType: SearchFilterContentTypes;
  className?: string;
  iconClassName: string;
  setAudioFiltersChanged: typeof setAudioFiltersChanged;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
  openDetailsInNewWindow?: boolean;
}

interface State {
  shouldShowSearchSimilarTooltip: boolean;
}

class SearchSimilarPopover extends Component<Props, State> {
  isMounted = false;

  static defaultProps = {
    openDetailsInNewWindow: false,
  };

  state = {
    shouldShowSearchSimilarTooltip: false,
  };

  componentDidMount = () => {
    this.isMounted = true;
  };

  componentWillUnmount = () => {
    this.isMounted = false;
  };

  onHover = () => {
    Telemetry.increment('audio.search.similar.hover');
    this.isMounted && this.setState({ shouldShowSearchSimilarTooltip: true });
  };

  hideAll = () => {
    this.isMounted && this.setState({ shouldShowSearchSimilarTooltip: false });
  };

  onClick = () => {
    const {
      stockItemId,
      stockItemTitle,
      selectedContentType: contentType,
    } = this.props;

    Telemetry.increment('audio.search.similar.click');
    const newSearchOptions = new AudioSearchOptions().update({
      similarTo: stockItemId,
      searchSimilarTitle: stockItemTitle,
      searchOrigin: searchOrigins.STOCK_ITEM_CARD,
      contentType,
      contentClass: ContentClass.Audio,
    });
    this.props.setAudioFiltersChanged(true);
    this.props.updateSearchOptionsAndRefresh(
      newSearchOptions,
      this.props.openDetailsInNewWindow
    );
  };

  render() {
    const { shouldShowSearchSimilarTooltip } = this.state;
    const { className, iconClassName } = this.props;

    return (
      <div className={`search-similar ${className}`}>
        <Popper
          placement={Placements.Left}
          popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
          showContent={shouldShowSearchSimilarTooltip}
          hideContent={this.hideAll}
          showArrow={shouldShowSearchSimilarTooltip}
          triggerElement={(referenceElement) => (
            <div
              role="button"
              tabIndex={0}
              className="action-wrapper flex cursor-pointer"
              ref={referenceElement}
              onMouseEnter={this.onHover}
              onFocus={this.onHover}
              onMouseLeave={() =>
                this.isMounted &&
                this.setState({ shouldShowSearchSimilarTooltip: false })
              }
              onBlur={() =>
                this.isMounted &&
                this.setState({ shouldShowSearchSimilarTooltip: false })
              }
              onClick={this.onClick}
              onKeyDown={this.onClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={iconClassName}
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="m23.5 17.577-3.963-3.964A8.752 8.752 0 1 0 4.339 5.024c.13.04.26.092.387.16L6.63 6.21a6.195 6.195 0 0 1 5.637-3.64 6.187 6.187 0 1 1-4.614 10.31l-2.306 1.24.148.186a8.752 8.752 0 0 0 11.629 1.722l3.963 3.963a1.728 1.728 0 0 0 2.414 0 1.707 1.707 0 0 0 0-2.414z"
                />
                <path d="M0 13.468V6.171a1 1 0 0 1 1.479-.878l6.69 3.649a1 1 0 0 1 0 1.755l-6.69 3.65A1 1 0 0 1 0 13.467z" />
              </svg>
            </div>
          )}
        >
          <div id="tooltip-search-similar" className="whitespace-nowrap">
            Search Similar
          </div>
        </Popper>
      </div>
    );
  }
}

export default connect(null, {
  setAudioFiltersChanged,
  updateSearchOptionsAndRefresh,
  // @ts-ignore
})(SearchSimilarPopover);
