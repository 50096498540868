import AudioSiteConstants from '../../../common/SiteConstants/AudioSiteConstants';
import ImageSiteConstants from '../../../common/SiteConstants/ImageSiteConstants';
import SiteConstants, {
  SiteEnum,
} from '../../../common/SiteConstants/SiteConstants';
import VideoSiteConstants from '../../../common/SiteConstants/VideoSiteConstants';
import { getCurrentSite } from '../../../common/utils';
import { SearchFilterContentTypes } from '../../Shared/enums';
import { ContentClass } from '../../Shared/enums';
import { legacyContentTypes as AudioContentTypes } from './AudioSearchFilterOptions';
import { legacyContentTypes as ImageContentTypes } from './ImageSearchFilterOptions';
import {
  contentTypes as VideoContentTypes,
  templateTypes,
} from './VideoSearchFilterOptions';

// todo: remove/replace these w/SearchFilterContentTypes
export const ALL_VIDEOS_CONTENT_TYPE =
  SearchFilterContentTypes.All_videos_content_type;
export const ALL_AUDIO_CONTENT_TYPE =
  SearchFilterContentTypes.All_audio_content_type;
export const ALL_IMAGES_CONTENT_TYPE =
  SearchFilterContentTypes.All_images_content_type;

export const VIDEO_CONTENT_CLASS = ContentClass.Video;
export const AUDIO_CONTENT_CLASS = ContentClass.Audio;
export const IMAGE_CONTENT_CLASS = ContentClass.Image;

class SearchFilterOptions {
  static getContentTypes() {
    const currentSite = getCurrentSite();

    switch (currentSite) {
      case SiteEnum.Graphicstock:
      case SiteEnum.Storyblocks:
        return ImageContentTypes;
      case SiteEnum.Videoblocks:
        return VideoContentTypes;
      case SiteEnum.Audioblocks:
        return AudioContentTypes;
    }

    return null;
  }

  static getTemplateTypes() {
    if (getCurrentSite() === SiteEnum.Videoblocks) {
      return templateTypes;
    }

    return null;
  }

  //contentClass is the term used in contentService, and all our codebases should align with this moving forward
  static getContentClass(site?: SiteEnum) {
    if (!site) {
      site = getCurrentSite();
    }

    switch (site) {
      case SiteEnum.Videoblocks:
        return ContentClass.Video;
      case SiteEnum.Graphicstock:
      case SiteEnum.Storyblocks:
        return ContentClass.Image;
      case SiteEnum.Audioblocks:
        return ContentClass.Audio;
      default:
        throw new Error('Cannot get content class for site: ' + site);
    }
  }

  static getSiteConstantsFromContentClass(contentClass: ContentClass) {
    if (!contentClass) {
      return SiteConstants.getInstance();
    }

    switch (contentClass) {
      case ContentClass.Video:
        return new VideoSiteConstants();
      case ContentClass.Audio:
        return new AudioSiteConstants();
      case ContentClass.Image:
        return new ImageSiteConstants();
    }

    return new VideoSiteConstants();
  }

  static getSiteFromContentClass(contentClass: ContentClass) {
    if (!contentClass) {
      return getCurrentSite();
    }

    switch (contentClass) {
      case ContentClass.Video:
        return SiteEnum.Videoblocks;
      case ContentClass.Audio:
        return SiteEnum.Audioblocks;
      case ContentClass.Image:
        return SiteEnum.Graphicstock;
    }

    return SiteEnum.Storyblocks;
  }

  static getContentClassFromContentType(contentType: SearchFilterContentTypes) {
    if (
      contentType === SearchFilterContentTypes.All_videos_content_type ||
      VideoContentTypes.find((item) => item.value === contentType) ||
      templateTypes.find((item) => item.urlId === contentType)
    ) {
      return this.getContentClass(SiteEnum.Videoblocks);
    } else if (
      contentType === SearchFilterContentTypes.All_audio_content_type ||
      AudioContentTypes.find((item) => item.value === contentType)
    ) {
      return this.getContentClass(SiteEnum.Audioblocks);
    } else if (
      contentType === SearchFilterContentTypes.All_images_content_type ||
      ImageContentTypes.find((item) => item.value === contentType)
    ) {
      return this.getContentClass(SiteEnum.Graphicstock);
    }
    return null;
  }
}

export default SearchFilterOptions;
