import React from 'react';
import { useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { getTrendingTagLinks } from '@videoblocks/shared-components';

import { SearchFilterContentTypes } from '../../../app/Shared/enums';
import { SearchOption } from '../../../app/Shared/types';
import { selectIsMobile } from '../../../auth/AuthSelectors';
import ProminentSearchContainer from '../containers/ProminentSearchContainer';
import { selectSearchFilterOptions } from '../selectors/searchSelectors';
import BackgroundVideo from './BackgroundVideo';

export const ProminentSearchOptionsContext = React.createContext<
  Array<SearchOption>
>([]);
ProminentSearchOptionsContext.displayName = 'ProminentSearchOptionsContext';

export const defaultContentTypes: SearchOption[] = [
  {
    id: SearchFilterContentTypes.All_videos_content_type,
    label: 'Video',
    placeholderText: 'Search video library...',
    videoSrc: '/home/video-homepage-hero-background.webm',
    posterSrc: '/home/video-homepage-hero-background.jpg',
  },
  {
    id: SearchFilterContentTypes.Templates,
    label: 'Video Templates',
    placeholderText: 'Search templates library...',
    videoSrc: '/home/template-homepage-hero-background.webm',
  },
  {
    id: SearchFilterContentTypes.All_audio_content_type,
    label: 'Audio',
    placeholderText: 'Search audio library...',
    videoSrc: '/home/audio-homepage-hero-background.webm',
  },
  {
    id: SearchFilterContentTypes.All_images_content_type,
    label: 'Images',
    placeholderText: 'Search image library...',
    videoSrc: '/home/image-homepage-hero-background.webm',
  },
];

interface Props {
  headingFirstLine?: string;
  headingSecondLine?: string;
  trendingTags?: string;
  contentTypes?: SearchOption[];
}

/**
 * @deprecated Use SearchHero instead
 */
function HomepageProminentSearch({
  headingFirstLine = 'Get Unlimited',
  headingSecondLine = 'Stock Media',
  trendingTags = 'Documentary,Love,Sport',
  contentTypes = defaultContentTypes,
}: Props) {
  const { contentType } = useSelector(selectSearchFilterOptions);
  const isMobile = useSelector(selectIsMobile);
  const currentIndex = contentTypes.findIndex(({ id }) => id === contentType);

  const eventTrackingForProminentSearch = () => {
    Telemetry.increment('homepagesearch.trending_keyword_click');
  };

  return (
    <div className="relative h-100 md:h-160">
      {/*Background*/}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden bg-gray-900">
        <div className="carousel overflow-hidden w-full h-full">
          <div
            className="carousel-content transition-all h-full whitespace-nowrap"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {!isMobile &&
              contentTypes.map(({ videoSrc, posterSrc }, idx) => (
                <BackgroundVideo
                  videoSrc={videoSrc}
                  posterSrc={posterSrc}
                  idx={idx}
                  key={idx}
                />
              ))}
          </div>
        </div>
      </div>
      {/*Header Container*/}
      <div className="justify-center flex w-full relative z-10 m-auto">
        <div className="gap-y-16 max-w-screen-2xl grid grid-cols-12 pt-8 px-4 md:pt-40 md:px-12 lg:px-24 lg:pt-40">
          {/*Header*/}
          <div className="justify-center flex flex-col col-span-full px-2 md:px-4 lg:px-4">
            {/*Header Text*/}
            <div className="justify-center flex flex-col pb-3 md:pb-6 lg:pb-6">
              <h1 className="font-bold text-white text-4xl mb-0 ppro-sm:text-5xl md:text-7xl lg:text-7xl">
                {headingFirstLine}
                <br />
                {headingSecondLine}
              </h1>
            </div>
            <ProminentSearchOptionsContext.Provider value={contentTypes}>
              <div id="unifiedNav-search-app" className="prominent-search-bar">
                <ProminentSearchContainer showDropDown={false} />
              </div>
            </ProminentSearchOptionsContext.Provider>
            <div className="flex pt-6 pb-8 md:pb-24 lg:pb-24">
              <p className="pr-1 text-white text-sm mobile-md:text-base ppro-sm:text-base md:text-base lg-text-base">
                Trending:
              </p>
              {trendingTags.split(',').map((tag, idx) => (
                <a
                  className="homepage-trending text-white text-sm mobile-md:text-base ppro-sm:text-base md:text-base lg-text-base font-bold underline pr-1 offset-5"
                  href={getTrendingTagLinks(
                    tag.toLowerCase().replace(/\s/g, '-'),
                    contentType
                  )}
                  onClick={eventTrackingForProminentSearch}
                  key={tag}
                >
                  {tag}
                  {idx === trendingTags.split(',').length - 1 ? '' : ','}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomepageProminentSearch;
