import React from 'react';
import { useSelector } from 'react-redux';

import { ChevronRight } from '@videoblocks/react-icons';

import { selectItemsByMenu } from '../../../../ui/UiSelectors';
import { BrowseLink } from '../../utils/browseLinkUtils';

const NavBarBrowseLinksWithColumnHeaders = ({
  menuName = '',
  showChevrons = false,
}: {
  menuName: string;
  showChevrons?: boolean;
}) => {
  const items = useSelector(selectItemsByMenu(menuName));

  return (
    <>
      {items.map((item) => {
        const { title } = item;

        if (!item.url) {
          return (
            <div
              key={title}
              className="subNav-subCategoryHeader text-gray-900 font-bold mt-7 lg:mt-0"
            >
              {title}&nbsp;
            </div>
          );
        }

        return (
          <li
            key={title}
            className="unifiedNav-browseLink lg:m-0 lg:py-1 pr-1 flex mt-6 lg:mt-0 hover:bg-gray-150"
            onKeyDown={(evt) => evt.currentTarget.querySelector('a').click()}
            onClick={(evt) => evt.currentTarget.querySelector('a').click()}
          >
            <span className="float-left flex-grow truncate">
              <BrowseLink url={item.url} className="font-normal text-gray-900">
                {title}
              </BrowseLink>
            </span>
            {showChevrons && (
              <span className="float-right pt-0.5">
                <ChevronRight className="text-gray-900 w-3 h-3" />
              </span>
            )}
          </li>
        );
      })}
    </>
  );
};

export default NavBarBrowseLinksWithColumnHeaders;
