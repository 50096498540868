import { debounce, DebouncedFunc } from 'lodash';
import Range from 'rc-slider/lib/Range';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setAudioFiltersChanged } from '../../../../Audio/actions/AudioActions';
import Collapse from '../../../../common/components/Collapse/Collapse';
import { searchOrigins } from '../../../Navigation/constants';
import { updateSearchOptionsAndRefresh } from '../../actions/SearchActions';
import { AudioSelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import { selectSearchFilterOptions } from '../../selectors/searchSelectors';
import ArrowIcon from './ArrowIcon';

import './SliderFilter.less';
import 'rc-slider/assets/index.css';

type Props = {
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  minValue?: number;
  maxValue?: number;
  minRangeValue: number;
  maxRangeValue: number;
  name: string;
  stepInterval: number;
  invalidSelectionMessage: string;
  isCollapsed: boolean;
  toggleCollapsed: () => void;
  setAudioFiltersChanged: typeof setAudioFiltersChanged;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
};

type State = {
  sliderOneValue: number;
  sliderTwoValue: number;
};

class TempoSliderFilter extends Component<Props, State> {
  fireSearch: DebouncedFunc<(opts: AudioSelectedSearchFilterOptions) => void>;

  constructor(props) {
    super(props);

    this.state = {
      sliderOneValue: props.minValue || props.minRangeValue,
      sliderTwoValue: props.maxValue || props.maxRangeValue,
    };

    this.fireSearch = debounce((newSearchOptions) => {
      this.props.setAudioFiltersChanged(true);
      this.props.updateSearchOptionsAndRefresh(newSearchOptions);
    }, 100);
  }

  static defaultProps = {
    stepInterval: 1,
  };

  componentDidUpdate(prevProps) {
    const { tempoMin, tempoMax } = this.props.selectedSearchFilterOptions;
    const { tempoMin: prevTempoMin, tempoMax: prevTempoMax } =
      prevProps.selectedSearchFilterOptions;

    if (prevTempoMin !== tempoMin || prevTempoMax !== tempoMax) {
      this.setNewValues([tempoMin, tempoMax]);
    }
  }

  setNewValues = (values: number[]) => {
    this.setState({
      sliderOneValue: values[0] || this.props.minRangeValue,
      sliderTwoValue: values[1] || this.props.maxRangeValue,
    });
  };

  grabNewSearchResults = (values: number[]) => {
    const { selectedSearchFilterOptions, minRangeValue, maxRangeValue } =
      this.props;
    const { tempoMin, tempoMax } = selectedSearchFilterOptions;

    const slOneValue = values[0];
    const slTwoValue = values[1];

    if (slOneValue !== tempoMin || slTwoValue !== tempoMax) {
      const validatedTempoMin =
        slOneValue !== minRangeValue ? slOneValue : null;
      const validatedTempoMax =
        slTwoValue !== maxRangeValue ? slTwoValue : null;
      const newSearchOptions = this.props.selectedSearchFilterOptions.update({
        tempoMin: validatedTempoMin,
        tempoMax: validatedTempoMax,
        page: 1,
        isPagination: false,
        searchOrigin: searchOrigins.FILTERS,
      }); // Go back to page 1 when any filter is changed

      this.fireSearch(newSearchOptions);
    }
  };

  render() {
    const {
      name,
      minRangeValue,
      maxRangeValue,
      stepInterval,
      invalidSelectionMessage,
      isCollapsed,
      toggleCollapsed,
    } = this.props;
    const { sliderOneValue = minRangeValue, sliderTwoValue = maxRangeValue } =
      this.state;

    const htmlSafeName = this.getHtmlSafeName(name);
    const isClosed = Boolean(isCollapsed || invalidSelectionMessage);

    return (
      <div
        id={`filter-group-${htmlSafeName}`}
        className={isClosed ? 'collapsed' : 'expanded'}
      >
        <h3
          role="button"
          tabIndex={0}
          className="heading"
          onKeyDown={toggleCollapsed}
          onClick={toggleCollapsed}
          aria-controls={htmlSafeName}
          aria-expanded={isClosed ? 'false' : 'true'}
        >
          {name}
          <ArrowIcon
            isCollapsed={isCollapsed}
            invalidSelectionMessage={this.props.invalidSelectionMessage}
          />
        </h3>

        {this.renderInvalidSelectionMessage() || (
          <>
            <Collapse isCollapsed={isClosed} controlId={htmlSafeName}>
              <div className="slider-container">
                <Range
                  className="tempo-slider"
                  min={minRangeValue}
                  max={maxRangeValue}
                  step={stepInterval}
                  defaultValue={[minRangeValue, maxRangeValue]}
                  value={[sliderOneValue, sliderTwoValue]}
                  onChange={(values) => this.setNewValues(values)}
                  onAfterChange={(values) => this.grabNewSearchResults(values)}
                  marks={{
                    [minRangeValue]: {
                      style: { left: '6%' },
                      label: `${sliderOneValue} bpm`,
                    },
                    [maxRangeValue]: {
                      style: { left: '90%' },
                      label: `${sliderTwoValue}${
                        sliderTwoValue === maxRangeValue ? '+' : ''
                      } bpm`,
                    },
                  }}
                />
              </div>
            </Collapse>

            {isCollapsed &&
              (sliderOneValue > minRangeValue ||
                sliderTwoValue < maxRangeValue) && (
                <div className="collapsible-filter-selected-option">
                  {sliderOneValue} bpm to {sliderTwoValue} bpm
                </div>
              )}
          </>
        )}
      </div>
    );
  }

  getHtmlSafeName(rawName) {
    return rawName.replace(/\W/g, '');
  }

  renderInvalidSelectionMessage() {
    return this.props.invalidSelectionMessage ? (
      <span className="invalid-message">
        {this.props.invalidSelectionMessage}
      </span>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  selectedSearchFilterOptions: selectSearchFilterOptions(state),
});

export default connect(mapStateToProps, {
  setAudioFiltersChanged,
  updateSearchOptionsAndRefresh,
})(TempoSliderFilter);
