import React from 'react';
import { useSelector } from 'react-redux';

import { mobileRightMenu } from '@videoblocks/shared-components/dist/cjs/components/MobileNav/MobileNavElements/const';
import MobileNav from '@videoblocks/shared-components/dist/mjs/components/MobileNav/MobileNavContainer';

import {
  selectIsLoggedIn,
  selectIsIpAuth,
  selectUser,
} from '../../../auth/AuthSelectors';
import useMedia from '../../../common/hooks/useMedia';
import { isEqual } from '../../../common/utils';
import {
  selectNavMenu,
  selectNavSubcategoriesForCategory,
  selectNavMenuCategory,
  selectNavSubCategoriesForMobile,
  selectFormattedNavMenuCollections,
} from '../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../ui/UiTypes';
import { videoOptions, audioOptions } from '../../Navigation/constants';
import { MainNavProps } from '../../Navigation/types';

const MobileNavContainer = () => {
  // MobileHamburgerMenuWidgetView.php
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isIpAuth = useSelector(selectIsIpAuth);
  const collections = useSelector(selectFormattedNavMenuCollections);
  const { top_level_categories, showFavorites, account } =
    useSelector(selectNavMenu);
  const businessCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.BUSINESS)
  ).categories;
  const resourcesCategories = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.RESOURCES)
  );
  const enterpriseSubCategories = useSelector(
    selectNavSubcategoriesForCategory('business', 'enterprise')
  ).filter((subCategory) => subCategory.title !== 'License Comparison');
  const subMenuCategories = useSelector(
    selectNavSubCategoriesForMobile(mobileRightMenu),
    isEqual
  );
  const constants = { audioOptions, videoOptions };
  const imagesUrl = __ASSETS_COMMON_IMAGES_URL__;

  const props: MainNavProps = {
    businessCategories,
    resourcesCategories,
    user,
    showFavorites,
    isLoggedIn,
    isIpAuth,
    top_level_categories,
    enterpriseSubCategories,
    imagesUrl,
    // @ts-expect-error templateOptions isn't meant to be passed in, it's generated within shared-components (mainNavContext)
    constants,
    useMedia,
    account,
    subMenuCategories,
    collections,
  };

  return <MobileNav {...props} />;
};

export default MobileNavContainer;
