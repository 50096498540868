import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '../../../../common/hooks/usePrevious';
import { isEqual } from '../../../../common/utils';
import { searchOrigins } from '../../../Navigation/constants';
import {
  updateSearchOptionsAndRefresh,
  updateTotalResultsCountForFilter,
} from '../../actions/SearchActions';
import { SelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import {
  categories as imageCategoryOptions,
  getCategoriesForContentType,
  mediaDetails as ImageMediaDetailsOptions,
} from '../../entities/ImageSearchFilterOptions';
import SearchFilterOptions, {
  ALL_IMAGES_CONTENT_TYPE,
} from '../../entities/SearchFilterOptions';
import SearchOptions from '../../entities/SearchOptions';
import {
  selectSearchFilterOptions,
  selectSearchResultsTotalCountsForFilters,
} from '../../selectors/searchSelectors';
import {
  FilterInputType,
  ImageFilterGroupNames,
} from '../menu/common/MenuEnums';
import { ImageFiltersOptions } from './common/FilterUtils';
import MobileFiltersContainer from './common/MobileFiltersContainer';
import MobileCategoriesFilter from './filters/MobileCategoriesFilter';
import MobileColorFilter from './filters/MobileColorFilter';
import MobileFileTypeFilter from './filters/MobileFileTypeFilter';
import MobileMediaDetailsFilter from './filters/MobileMediaDetailsFilter';
import MobileMediaTypeFilter from './filters/MobileMediaTypeFilter';
import MobileOrientationFilter from './filters/MobileOrientationFilter';

const getCurrentCategoryValue = (selectedCategoryId, options) => {
  const selectedCategory = options.find(
    (category) => category.categoryId == selectedCategoryId
  );

  return selectedCategory ? selectedCategory.value : '';
};

interface MobileImageFiltersProps {
  setAppliedFiltersTotalCount: (newAppliedFiltersCount: number) => void;
}

const MobileImageFilters = ({
  setAppliedFiltersTotalCount,
}: MobileImageFiltersProps): JSX.Element => {
  const searchResultsTotalCountsForFilters = useSelector(
    selectSearchResultsTotalCountsForFilters
  );
  const selectedSearchFilterOptionsRedux = useSelector(
    selectSearchFilterOptions
  );

  // need to have a local filer states to keep tracks for filters change before updates to global filter
  // state because results won't be show until user press show results
  // and exiting the mobile filter without pressing show results button will reset to the prior filters state
  const [
    selectedSearchFilterOptionsLocal,
    setSelectedSearchFilterOptionsLocal,
  ] = useState({ ...selectedSearchFilterOptionsRedux });

  const [collapsed, setCollapsed] = useState({
    [ImageFiltersOptions.CATEGORIES]: false,
    [ImageFiltersOptions.CONTENT_TYPE]: false,
    [ImageFiltersOptions.ORIENTATION]: false,
    [ImageFiltersOptions.COLOR]: false,
    [ImageFiltersOptions.MEDIA_DETAILS]: false,
    [ImageFiltersOptions.FILE_TYPE]: false,
  });

  const toggleCollapsed = (name) => {
    setCollapsed({
      ...collapsed,
      [name]: !collapsed[name],
    });
  };

  const previousSelectedFilterOptionsLocal = usePrevious(
    selectedSearchFilterOptionsLocal
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSearchFilterOptionsRedux) {
      setSelectedSearchFilterOptionsLocal({
        ...selectedSearchFilterOptionsLocal,
      });
      dispatch(
        updateTotalResultsCountForFilter(selectedSearchFilterOptionsRedux)
      );
    }
  }, [selectedSearchFilterOptionsRedux]);

  // debouncing calls to the search service to fetch total results count
  // so rapid changes to the filters won't trigger multiple api calls
  // necessary for sliders like filters, use for checkbox like filters
  const debouncedUpdateTotalResultsCountForFilter = useCallback(
    debounce((newSearchOptions: SelectedSearchFilterOptions) => {
      dispatch(updateTotalResultsCountForFilter(newSearchOptions));
    }, 100),
    [dispatch]
  );

  // monitoring selectedSearchFilterOptionsLocal and update TotalResultsCount
  // when selectedSearchFilterOptionsLocal changes
  useEffect(() => {
    if (
      previousSelectedFilterOptionsLocal &&
      selectedSearchFilterOptionsLocal &&
      previousSelectedFilterOptionsLocal !== selectedSearchFilterOptionsLocal
    ) {
      const updateFields = {
        ...selectedSearchFilterOptionsLocal,
        page: 1, // Go back to page 1 when any filter is changed
        isPagination: false,
        searchOrigin: searchOrigins.FILTERS,
      };
      // selectedSearchFilterOptionsRedux.update just returns a new search option object,
      // and doesn't affect the original one, so it won't cause an infinite loop
      const newSearchOptions =
        selectedSearchFilterOptionsRedux.update(updateFields);
      // update update total result count to display within the mobile filter
      // doesn't change filters global redux state yet
      debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
    }
  }, [previousSelectedFilterOptionsLocal, selectedSearchFilterOptionsLocal]);

  useEffect(() => {
    if (selectedSearchFilterOptionsLocal) {
      const defaultSelectedSearchOptions =
        SearchOptions.getDefaultSearchOptions();
      const numberOfAppliedFilters = Object.values(ImageFiltersOptions).reduce(
        (acc, key) => {
          if (
            key === ImageFiltersOptions.PROPERTY_RELEASED ||
            key === ImageFiltersOptions.TALENT_RELEASED ||
            key == ImageFiltersOptions.HAS_TRANSPARENCY
          ) {
            if (selectedSearchFilterOptionsLocal[key]) {
              return acc + 1;
            }
          } else if (
            !isEqual(
              selectedSearchFilterOptionsLocal[key],
              defaultSelectedSearchOptions[key]
            )
          ) {
            return acc + 1;
          }
          return acc;
        },
        0
      );
      setAppliedFiltersTotalCount(numberOfAppliedFilters);
    }
  }, [selectedSearchFilterOptionsLocal]);

  const handleCheckboxAndRadioFiltersChange = (name, value) => {
    setSelectedSearchFilterOptionsLocal({
      ...selectedSearchFilterOptionsLocal,
      [name]: value,
    });
  };

  const handleMediaTypeChange = (name, value) => {
    const newState = {
      ...selectedSearchFilterOptionsLocal,
      contentClass: SearchFilterOptions.getContentClassFromContentType(value),
      categories: '',
      [name]: value,
    };
    setSelectedSearchFilterOptionsLocal(newState);
  };

  const handleColorChange = (value) => {
    const newState = {
      ...selectedSearchFilterOptionsLocal,
      color: value,
    };
    setSelectedSearchFilterOptionsLocal(newState);
  };
  const invalidSelectionMessage =
    selectedSearchFilterOptionsLocal.contentType === ALL_IMAGES_CONTENT_TYPE &&
    'Select Media Type to see Categories';

  const allCategoriesOption = getCategoriesForContentType(
    selectedSearchFilterOptionsLocal.contentType
  );

  const checkedCategoryOption = getCurrentCategoryValue(
    selectedSearchFilterOptionsLocal.categories,
    imageCategoryOptions
  );

  const handleClearFilters = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    let newSearchOptions = SearchOptions.getDefaultSearchOptions();

    newSearchOptions = newSearchOptions.update({
      searchTerm: selectedSearchFilterOptionsRedux.searchTerm,
    });

    setSelectedSearchFilterOptionsLocal({
      ...newSearchOptions,
    });

    debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
  };

  const handleShowResult = () => {
    const updateFields = {
      ...selectedSearchFilterOptionsLocal,
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.FILTERS,
    };
    const newSearchOptions =
      selectedSearchFilterOptionsRedux.update(updateFields);
    dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };

  return (
    <MobileFiltersContainer
      resultsTotalCount={searchResultsTotalCountsForFilters}
      handleClearAll={handleClearFilters}
      handleShowResult={handleShowResult}
    >
      <div className="flex flex-col">
        <MobileMediaTypeFilter
          onChange={handleMediaTypeChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[ImageFiltersOptions.MEDIA_DETAILS]}
          toggleCollapsed={() =>
            toggleCollapsed(ImageFiltersOptions.MEDIA_DETAILS)
          }
        />
        <MobileOrientationFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[ImageFiltersOptions.ORIENTATION]}
          toggleCollapsed={() =>
            toggleCollapsed(ImageFiltersOptions.ORIENTATION)
          }
        />
        <MobileColorFilter
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          onChange={handleColorChange}
          collapsed={collapsed[ImageFiltersOptions.COLOR]}
          toggleCollapsed={() => toggleCollapsed(ImageFiltersOptions.COLOR)}
        />
        <MobileMediaDetailsFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          mediaDetailsOptions={ImageMediaDetailsOptions}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[ImageFiltersOptions.MEDIA_DETAILS]}
          toggleCollapsed={() =>
            toggleCollapsed(ImageFiltersOptions.MEDIA_DETAILS)
          }
        />
        <MobileCategoriesFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          allOptions={allCategoriesOption}
          checkedOption={checkedCategoryOption}
          invalidSelectionMessage={invalidSelectionMessage}
          collapsed={collapsed[ImageFiltersOptions.CATEGORIES]}
          toggleCollapsed={() =>
            toggleCollapsed(ImageFiltersOptions.CATEGORIES)
          }
          groupName={ImageFilterGroupNames.CATEGORIES}
          inputType={FilterInputType.RADIO}
        />
        <MobileFileTypeFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[ImageFiltersOptions.FILE_TYPE]}
          toggleCollapsed={() => toggleCollapsed(ImageFiltersOptions.FILE_TYPE)}
        />
      </div>
    </MobileFiltersContainer>
  );
};

export default MobileImageFilters;
