import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  COLLECTIONS,
  StockMediaMenuState,
  TemplateOptions,
} from '@videoblocks/shared-components';

import { CategoryLinkColumns } from '../../Search/components/nav/CategoryLinksList';
import NavBarBrowseLinksWithColumnHeaders from '../../Search/components/nav/NavBarBrowseLinksWithColumnHeaders';
import { SubcategoryLinkColumns } from '../../Search/components/nav/SubcategoryLinksList';
import { videoOptions, audioOptions } from '../constants';
import {
  TemplateLinkColumns,
  NavMenuCollectionsLinkColumns,
  MusicLinkColumns,
  SoundFXLinkColumns,
  ColumnLink,
} from './LinkColumns';

const Wrapper: React.FC<{ className?: string }> = ({ children, className }) => (
  <div
    className={twMerge(
      'grid grid-cols-2 grid-flow-col gap-y-2 grid-rows-10',
      className
    )}
  >
    {children}
  </div>
);

const MiddleColumn = ({
  menuState,
  updateMenuState,
}: {
  menuState: StockMediaMenuState;
  updateMenuState: (menuState: object) => void;
}) => {
  const { subCategories, selectedMenu, selectedCategory } = menuState;

  if (
    [videoOptions.STOCK_FOOTAGE, videoOptions.BACKGROUNDS].includes(
      selectedMenu
    )
  ) {
    if (subCategories?.length > 0) {
      return (
        <Wrapper>
          <ColumnLink
            labelClassName="font-bold"
            item={{
              url: selectedCategory.url,
              title: `All ${selectedCategory.title}`,
            }}
          />
          <SubcategoryLinkColumns subCategoryList={subCategories} />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <CategoryLinkColumns
          menuName={selectedMenu}
          onClick={(selectedCategory) => updateMenuState({ selectedCategory })}
        />
      </Wrapper>
    );
  }

  if (selectedMenu === COLLECTIONS) {
    return (
      <Wrapper className="grid-cols-3 grid-rows-12">
        <NavMenuCollectionsLinkColumns />
      </Wrapper>
    );
  }

  if (selectedMenu === audioOptions.MUSIC) {
    return <MusicLinkColumns />;
  }

  if (selectedMenu === audioOptions.EFFECTS) {
    return (
      <Wrapper className="subNav-subCategory grid-cols-5 gap-y-1 grid-rows-13">
        <SoundFXLinkColumns rowsPerColumn={13} />
      </Wrapper>
    );
  }

  if (Object.values(TemplateOptions).includes(selectedMenu)) {
    return (
      <Wrapper className="subNav-subCategory grid-cols-2 gap-y-1 grid-rows-6">
        <TemplateLinkColumns selectedMenu={selectedMenu} />
      </Wrapper>
    );
  }

  return (
    <Wrapper className="subNav-subCategory gap-x-4 grid-cols-4 gap-y-1 grid-rows-13">
      <NavBarBrowseLinksWithColumnHeaders
        menuName={selectedMenu}
        showChevrons
      />
    </Wrapper>
  );
};

export default React.memo(MiddleColumn);
