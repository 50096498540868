import React from 'react';

import { Maker } from '@videoblocks/react-icons';
import AdobeCcLogoMonochromeIcon from '@videoblocks/react-icons/AdobeCcLogoMonochrome';
import MakerTeamsIcon from '@videoblocks/react-icons/MakerTeams';
import StoryboardAiLogoLightIcon from '@videoblocks/react-icons/StoryboardAiLogoLight';

import { AudioNavOptions } from '../../common/SiteConstants/AudioSiteConstants';
import siteConstants, {
  SiteEnum,
} from '../../common/SiteConstants/SiteConstants';
import { VideoNavOptions } from '../../common/SiteConstants/VideoSiteConstants';

export const videoOptions = siteConstants
  .buildInstance(SiteEnum.Videoblocks)
  .getStockMediaNavOptions() as VideoNavOptions;

export const audioOptions = siteConstants
  .buildInstance(SiteEnum.Audioblocks)
  .getStockMediaNavOptions() as AudioNavOptions;

export const searchOrigins = {
  FILTERS: 'filters',
  SEARCH_BAR: 'search_bar',
  SORT_BY: 'sort_by',
  TYPE_AHEAD: 'type_ahead',
  STOCK_ITEM_CARD: 'stock_item_card',
  UNKNOWN: '(unknown)',
  HOME: 'home',
  TOP_NAV: 'top_nav',
  DETAILS_PAGE: 'details_page',
  RELATED_SEARCH: 'related-search',
  PAGINATION: 'pagination',
  ARTIST: 'artist',
  APPLIED_FILTERS: 'applied_filters',
  AUTOCORRECT: 'autocorrect',
};

type SVGComponent = React.FC<React.SVGProps<SVGSVGElement>>;
type creativeToolsMenuOptions = Record<
  string,
  { icon: SVGComponent; description: string }
>;

export const CreativeToolsMenuOptions: creativeToolsMenuOptions = {
  'adobe-creative-cloud-plugin': {
    icon: AdobeCcLogoMonochromeIcon,
    description:
      'Produce videos faster with unlimited access to our library, directly in Premiere Pro and After Effects.',
  },
  maker: {
    icon: Maker,
    description:
      'Create videos easily with our online editing tool, integrated with the Storyblocks library.',
  },
  'maker-for-teams': {
    icon: MakerTeamsIcon,
    description:
      'Exclusive features for businesses to get to market faster with brands, templates, and shared projects.',
  },
  'storyboard-ai': {
    icon: StoryboardAiLogoLightIcon,
    description:
      'Get your ideas off the ground quickly with AI-powered scene recommendations.',
  },
};
