import React, { useEffect } from 'react';

declare global {
  interface Window {
    [key: string]: any;
  }
}

const loadExternalScript = async (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;

    script.onload = () => {
      resolve(script);
    };

    script.onerror = (error) => {
      reject(error);
    };

    document.head.appendChild(script);
  });
};

/**
 * This component also exists in storyblocks-shell. The two should be kept in sync
 * until shared-components goes away.
 */
const ChiliPiperLoader = () => {
  useEffect(() => {
    const scriptSrc = 'https://js.chilipiper.com/marketing.js';
    const cpTenantDomain = 'videoblocks';
    const cpRouterName = 'inbound-router';
    let lead = {};

    loadExternalScript(scriptSrc)
      .then(() => {
        window.addEventListener('message', function (event) {
          if (event.data.type === 'hsFormCallback') {
            if (event.data.eventName === 'onFormSubmit') {
              for (const key in event.data.data) {
                if (Array.isArray(event.data.data[key].value)) {
                  event.data.data[key].value = event.data.data[key].value
                    .toString()
                    .replaceAll(',', ';');
                }
                lead[event.data.data[key].name] = event.data.data[key].value;
              }
              if (Object.keys(lead).length <= 1) {
                lead = event.data.data;
              }
            } else if (event.data.eventName === 'onFormSubmitted') {
              window.ChiliPiper.submit(cpTenantDomain, cpRouterName, {
                map: true,
                lead: lead,
              });
            }
          }
        });
      })
      .catch((error) => {
        // Handle any errors that occurred while loading the script.
        console.error('Error loading external script:', error);
      });
  }, []);

  return <div id="chili-piper" />;
};

export default ChiliPiperLoader;
