export enum MediaTypesRoutes {
  VideoFootage = '/video/footage',
  VideoMotionBackgrounds = '/video/motion-backgrounds',
  VideoAeTemplates = '/video/templates/after-effects',
  VideoTemplates = '/video/templates',

  TemplatePremierePro = '/video/templates/premiere-pro',
  TemplateAfterEffects = '/video/templates/after-effects',
  TemplateDaVinciResolve = '/video/templates/davinci-resolve',
  TemplateAppleMotion = '/video/templates/apple-motion',

  ImagesVectors = '/images/vectors',
  ImagesPhotos = '/images/photos',
  ImagesIllustrations = '/images/illustrations',

  AudioMusic = '/audio/music',
  AudioSfx = '/audio/sound-effects',
}

export enum MemberMediaTypesRoutes {
  VideoFootage = '/video/search',
  VideoMotionBackgrounds = '/motion-graphics/search',

  Templates = '/templates/search',
  TemplatePremierePro = '/templates/premiere-pro-templates',
  TemplateAfterEffects = '/templates/after-effects-templates',
  TemplateDaVinciResolve = '/templates/davinci-resolve-templates',
  TemplateAppleMotion = '/templates/apple-motion-templates',

  ImagesVectors = '/images/vectors',
  ImagesPhotos = '/images/photos',
  ImagesIllustrations = '/images/illustrations',

  AudioMusic = '/audio/search?media-type=music',
  AudioSfx = '/audio/search?media-type=sound-effects',
}

export enum HomeRoutes {
  Videoblocks = '/video',
  Audioblocks = '/audio',
  Graphicstock = '/images',
}

export enum ContentClass {
  Video = 'video',
  Audio = 'audio',
  Image = 'image',
}

export enum ContentType {
  Footage = 'footage',
  Template = 'template',
  Motion_bg = 'motion-background',
  Vr_360 = 'vr360',
  Illustration = 'illustration',
  Vector = 'vector',
  Photo = 'photo',
  Sound_effect = 'sfx',
  Music = 'music',
}

export enum SearchFilterContentTypes {
  All_videos_content_type = 'all-videos',
  All_audio_content_type = 'all-audio',
  All_images_content_type = 'all-images',
  Footage = 'footage',
  Motion_bgs = 'motion-backgrounds',
  Templates = 'templates',
  Premiere_pro_templates = 'premiere-pro-templates',
  After_effects = 'after-effects-templates',
  Davinci_resolve = 'davinci-resolve-templates',
  Apple_motion = 'apple-motion-templates',
  Music = 'music',
  Sound_effects = 'sfx',
  Photos = 'photos',
  Snapshots = 'snapshots',
  Vectors = 'vectors',
  Illustrations = 'illustrations',
}

export enum AdobePluginSuggestionVariant {
  MARKETPLACE,
  LANDING_PAGE,
}
