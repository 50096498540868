import React from 'react';
import { useSelector } from 'react-redux';

import Logger from '../../../common/Logger';
import { selectNavMenuCategory } from '../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../ui/UiTypes';
import { MenuOption } from '../types';
import TalkToSalesButton from './TalkToSalesButton';

const BusinessMenu = () => {
  const { categories } = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.BUSINESS)
  );
  const useCasesSubMenu = categories.enterprise.subCategories;

  const options: Array<MenuOption> = [
    {
      title: 'PRODUCTS & TOOLS',
      subMenu: [
        {
          title: 'Storyblocks for Business',
          url: '/resources/business-solutions/business-license',
        },
        {
          title: 'Watch a Demo',
          url: '/resources/business-solutions/storyblocks-demo',
        },
        {
          title: 'Storyblocks API',
          url: '/resources/business-solutions/api',
        },
        {
          title: 'Data Licensing',
          url: '/resources/business-solutions/data-licensing',
        },
      ],
    },
    {
      title: 'INDUSTRIES',
      subMenu: useCasesSubMenu,
      telemetry: 'topnav.businesses.corpsub.homepage',
    },
    {
      title: 'START THE CONVERSATION',
    },
  ];

  return (
    <>
      {options.map((opt) => (
        <div
          className="flex flex-col px-4 pt-6 pb-6 space-y-2 border-b border-gray-100 border-solid border-t-0 border-l-0 border-r-0 font-inter"
          key={opt.title}
        >
          <div
            role="heading"
            aria-level={2}
            className="text-gray-900 font-semibold text-xs tracking-widest text-left font-inter uppercase leading-none"
          >
            {opt.title}
          </div>
          {opt.subMenu && (
            <ul className="m-0 list-none pt-0 pb-none">
              {opt.subMenu.map((listItem) => (
                <li
                  className={
                    listItem.title === 'Watch a Demo' ? 'my-4' : 'pb-1'
                  }
                  key={listItem.url}
                >
                  <a
                    className={`${
                      listItem.title === 'Watch a Demo'
                        ? 'text-white bg-blue-600 hover:bg-blue-700 rounded-lg border-none px-8 py-3'
                        : 'text-left text-gray-800 leading-snug'
                    } font-semibold font-inter no-underline`}
                    href={listItem.url}
                    onClick={() =>
                      // todo: Telemetry.increment()?
                      Logger.accessTelemetry().increment(listItem.telemetry)
                    }
                  >
                    {listItem.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
          {opt.title === 'START THE CONVERSATION' && <TalkToSalesButton />}
        </div>
      ))}
    </>
  );
};

export default BusinessMenu;
