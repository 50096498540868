import React from 'react';
import Modal from 'react-modal';
import { twMerge } from 'tailwind-merge';

Modal.setAppElement('body');

interface ModalDialogProps extends React.PropsWithChildren<Modal.Props> {
  className?: string;
  contentLabel?: string;
  contentRef?: (node: HTMLDivElement) => void;
  headerText?: string;
  initiallyFocusedElement?: HTMLElement;
  onRequestClose?: () => void;
  overlayClassName?: string;
  subHeaderText?: string | React.ReactNode;
  topOffsetClass?: string;
  contentLabelClassName?: string;
  contentLabelContainerClassName?: string;
  closeButtonClassName?: string;
  isOpen: boolean;
  id?: string;
  shouldCloseOnOverlayClick?: boolean;
  closeTimeoutMS?: number;
}

/**
 * This wraps the react-modal component with some Storyblocks-specific
 * transition and layout styling.
 */
const ModalDialog = (props: ModalDialogProps) => {
  const {
    children,
    className = '',
    contentLabel,
    headerText,
    onRequestClose,
    overlayClassName = '',
    subHeaderText = '',
    contentLabelClassName = '',
    topOffsetClass = 'top-20',
    contentLabelContainerClassName = '',
    closeButtonClassName = '',
  } = props;

  const handleRequestClose = () => {
    onRequestClose?.();
  };

  return (
    <Modal
      {...props}
      onRequestClose={handleRequestClose}
      overlayClassName={twMerge(
        'bg-black z-1000 fixed w-full h-full top-0 bg-opacity-60',
        overlayClassName
      )}
      className={twMerge(
        'shadow-popover absolute inset-x-0 top-0 max-w-xl px-8 pt-12 pb-10 mx-auto transition-all ease-out rounded-lg opacity-100 bg-white',
        topOffsetClass,
        className
      )}
    >
      <button
        className={twMerge(
          'focus:text-gray-900 hover:text-gray-900 top-4 right-4 absolute w-6 h-5 m-0 text-4xl leading-5 text-gray-600 transform rotate-45 border-none outline-none',
          closeButtonClassName
        )}
        onClick={handleRequestClose}
        aria-label="Close modal"
      >
        +
      </button>

      {(headerText || contentLabel || subHeaderText) && (
        <div
          className={twMerge(
            'w-full m-0 mb-10 tracking-wide space-y-4',
            contentLabelContainerClassName
          )}
        >
          {(headerText || contentLabel) && (
            <h2
              className={twMerge('text-2xl font-bold', contentLabelClassName)}
            >
              {headerText || contentLabel}
            </h2>
          )}
          {subHeaderText && <div className="text-lg">{subHeaderText}</div>}
        </div>
      )}

      {children}
    </Modal>
  );
};

export default ModalDialog;
