import { partition } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import BetaBadge from '../../../common/components/BetaBadge';
import { slugify } from '../../../common/utils';
import { selectNavMenuCategory } from '../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../ui/UiTypes';
import { CreativeToolsMenuOptions } from '../constants';

const CreativeToolsMenu = () => {
  const { categories } = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.CREATIVE_TOOLS)
  );

  const [categoriesWithNewFeatures, filteredCategories] = partition(
    Object.keys(categories),
    (categoryName) => categories[categoryName]?.newFeature
  );

  const categoryNames = [
    ...categoriesWithNewFeatures.sort(),
    ...filteredCategories.sort(),
  ];

  return (
    <>
      {categoryNames.map((opt) => {
        const category = categories[opt];
        const menuOption = CreativeToolsMenuOptions[opt];

        if (!category) {
          return null;
        }

        if (!menuOption) {
          return null;
        }

        const IconComponent = menuOption.icon;

        return (
          <div
            role="button"
            tabIndex={0}
            className="CreativeTools-option creativeToolsLabel w-72 px-6 py-2 flex flex-col justify-center hover:bg-gray-100"
            key={categories[opt].title}
            data-cy={slugify(categories[opt].title)}
            onClick={(evt) =>
              (evt.currentTarget.firstChild as HTMLLinkElement).click()
            }
            onKeyDown={(evt) =>
              (evt.currentTarget.firstChild as HTMLLinkElement).click()
            }
          >
            <a
              className="border-solid border-0 border-current flex flex-col items-start text-gray-900 font-normal"
              href={categories[opt]?.url}
            >
              <div className="flex items-center">
                <div className="w-6 pr-1">
                  {menuOption.icon && <IconComponent id={`icon-${opt}`} />}
                </div>
                <div className="font-semibold uppercase tracking-widest text-xs ml-1 no-underline">
                  {categories[opt].title}
                </div>
                {categories[opt]?.isBeta && <BetaBadge className="mx-2" />}
              </div>
              <div className="leading-7 ml-7">
                {CreativeToolsMenuOptions[opt]?.description}
              </div>
            </a>
          </div>
        );
      })}
    </>
  );
};

export default CreativeToolsMenu;
