import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import Logger from '../../../common/Logger';
import { AudioNavOptions } from '../../../common/SiteConstants/AudioSiteConstants';
import siteConstants, {
  SiteEnum,
} from '../../../common/SiteConstants/SiteConstants';
import { VideoNavOptions } from '../../../common/SiteConstants/VideoSiteConstants';
import useMedia from '../../../common/hooks/useMedia';
import { titleCase, slugify } from '../../../common/utils';
import {
  selectFormattedNavMenuCollections,
  selectNavMenuCategory,
  selectItemsByMenu,
  selectNavMenu,
} from '../../../ui/UiSelectors';
import { NavCategory } from '../../../ui/UiTypes';

const videoOptions = siteConstants
  .buildInstance(SiteEnum.Videoblocks)
  .getStockMediaNavOptions() as VideoNavOptions;
const audioOptions = siteConstants
  .buildInstance(SiteEnum.Audioblocks)
  .getStockMediaNavOptions() as AudioNavOptions;

export const ColumnTitle = ({
  item: { title },
  containerProps = {},
  labelClassName,
}: {
  item: NavCategory;
  containerProps?: Partial<{ className: string; [key: string]: string }>;
  labelClassName?: string;
}) => (
  <div
    className={twMerge(
      'unifiedNav-browseLink lg:m-0 mt-5 lg:h-8 lg:pt-1 lg:pl-5 pr-5',
      containerProps?.className
    )}
    key={title}
    data-cy={containerProps?.['data-cy'] || slugify(title)}
  >
    <div className="flex flex-row text-gray-900">
      <span
        className={twMerge(
          'float-left flex-grow truncate font-normal',
          labelClassName
        )}
      >
        {titleCase(title.replace(/-/g, ' '))}
      </span>
    </div>
  </div>
);

export const ColumnLink = ({
  item: { title, url, telemetry },
  containerProps = {},
  labelClassName,
}: {
  item: NavCategory;
  containerProps?: Partial<{ className: string; [key: string]: string }>;
  labelClassName?: string;
}) => {
  const handleClick = () => {
    if (telemetry) {
      Logger.accessTelemetry().increment(telemetry);
    }
  };

  // i.e. <CategoryLink />
  return (
    <div
      role="button"
      tabIndex={0}
      className={twMerge(
        'unifiedNav-browseLink lg:m-0 mt-5 lg:h-8 lg:pt-1 hover:bg-blue-100 lg:pl-5 pr-5',
        containerProps?.className
      )}
      onClick={handleClick}
      onKeyDown={handleClick}
      key={url}
      data-cy={containerProps?.['data-cy'] || slugify(title)}
    >
      <a
        className="flex flex-row text-gray-900"
        tabIndex={0}
        href={url}
        onClick={() => {
          if (telemetry) Logger.accessTelemetry().increment(telemetry);
        }}
      >
        <span
          className={twMerge(
            'float-left flex-grow truncate font-normal',
            labelClassName
          )}
        >
          {titleCase(title.replace(/-/g, ' '))}
        </span>
      </a>
    </div>
  );
};

export const TemplateLinkColumns = ({
  selectedMenu = videoOptions.TEMPLATES,
}: {
  selectedMenu?: string;
}) => {
  const items = useSelector(selectItemsByMenu(selectedMenu));

  return (
    <>
      {items.map((item) => (
        <ColumnLink
          item={item}
          key={item.url}
          labelClassName={item.title.endsWith('Templates') && 'font-bold'}
        />
      ))}
    </>
  );
};

export const NavMenuCollectionsLinkColumns = () => {
  const navMenuCollections = useSelector(selectFormattedNavMenuCollections);

  return (
    <>
      {Object.keys(navMenuCollections).map((contentClass) => (
        <React.Fragment key={contentClass}>
          <span className="text-gray-900 pt-2 font-bold block mt-5 lg:mt-0 lg:pl-5">
            {titleCase(contentClass)}&nbsp;Collections
          </span>
          {(navMenuCollections[contentClass] as NavCategory[])
            .filter((item) => !!item.url)
            .map((item) => (
              <ColumnLink
                item={item}
                key={item.url}
                containerProps={{
                  'data-cy': `${contentClass}-${slugify(item.title)}`,
                }}
                labelClassName={item.url.endsWith('collections') && 'font-bold'}
              />
            ))}
        </React.Fragment>
      ))}
    </>
  );
};

export const MusicLinkColumns = () => {
  const { categories } = useSelector(selectNavMenuCategory(audioOptions.MUSIC));
  const isSmallScreen = useMedia('(max-width: 673px)', true);
  const ITEMS_PER_ROW = 10;

  return (
    <>
      {!isSmallScreen && (
        <div className="pl-5 pb-2 w-full pr-3 grid grid-rows-1 grid-cols-4 gap-x-7">
          {Object.keys(categories).map((key) => (
            <div
              className={twMerge(
                'font-bold text-gray-900',
                key === 'genres' && 'col-span-2'
              )}
              key={key}
            >
              {titleCase(key)}
            </div>
          ))}
        </div>
      )}
      <div
        className={twMerge(
          'subNav-subCategory',
          isSmallScreen
            ? 'flex-grow'
            : `grid grid-flow-col grid-cols-4 gap-y-1.5 grid-rows-${ITEMS_PER_ROW}`
        )}
      >
        {Object.keys(categories).map((key) => {
          let { subCategories: items } = categories[key];

          const itemsShortOfRowLength = Math.abs(ITEMS_PER_ROW - items.length);

          if (isSmallScreen) {
            // inline category on mobile
            items = [{ url: '', title: key }, ...items];
          } else if (itemsShortOfRowLength < ITEMS_PER_ROW) {
            items = [
              ...items,
              ...(Array.from({ length: itemsShortOfRowLength }).fill(
                {}
              ) as NavCategory[]),
            ];
          }

          return items.map((item) => {
            if (!item.title) {
              return <div key={item.url} />;
            }
            if (!item.url) {
              return (
                <ColumnTitle
                  key={item.title}
                  item={item}
                  containerProps={{
                    className: twMerge('pr-3.5 mt-7'),
                  }}
                  labelClassName="font-bold"
                />
              );
            }

            return (
              <ColumnLink
                item={item}
                key={item.url}
                containerProps={{
                  className: twMerge('pr-3.5'),
                }}
              />
            );
          });
        })}
      </div>
    </>
  );
};

export const SoundFXLinkColumns = ({
  rowsPerColumn,
}: {
  rowsPerColumn?: number;
}) => {
  const items = useSelector(selectItemsByMenu(audioOptions.EFFECTS));

  /**
   * because these items are automatically laid out in a css grid w/rows & cols,
   * we don't have a great way to target items just in the last column (yet) [0].
   * instead, calculate the offset idx where the last column starts, style
   *
   * [0] `:nth-col()` and `:nth-last-col()` pseudo-selectors are incoming at some
   * point, don't have any browser support as of this implementation
   * https://www.w3.org/TR/selectors-4/#table-pseudos
   * https://developer.mozilla.org/en-US/docs/Web/CSS/:nth-col
   */
  const numberOfItemsInLastColumn =
    items.length % (rowsPerColumn || items.length);
  const indexOfLastColumn = items.length - numberOfItemsInLastColumn;

  return (
    <>
      {items.map((item, idx) => (
        <ColumnLink
          item={item}
          key={item.url}
          containerProps={{
            className: twMerge(
              'lg:pl-4',
              idx >= indexOfLastColumn ? 'pr-3' : 'pr-2'
            ),
          }}
        />
      ))}
    </>
  );
};

export const ImageLinkColumns = () => {
  const imageMenu = [
    {
      url: '/images/vectors',
      title: 'Vectors',
    },
    {
      url: '/images/illustrations',
      title: 'Illustrations',
    },
    {
      url: '/images/photos',
      title: 'Photos',
    },
    {
      url: '/images/collections',
      title: 'Collections',
    },
  ];
  return (
    <div className="subNav-subCategory gap-x-4 grid grid-cols-1">
      {imageMenu.map((item) => {
        return <ColumnLink item={item} key={item.url} />;
      })}
    </div>
  );
};

export const AccountLinkColumns = () => {
  const { account } = useSelector(selectNavMenu);

  return (
    <div className="subNav-subCategory gap-x-4 grid grid-cols-1">
      {Object.values(account).map((menu) => (
        <ColumnLink item={menu} key={menu.url} />
      ))}
    </div>
  );
};
