import { Builder } from '@builder.io/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import NavSearch from '@videoblocks/shared-components/dist/mjs/components/Search/NavSearch';

import { isSearchAppLoaded } from '../../../app/Search/actions/SearchActions';
import { handleSubmit } from '../../../app/Search/containers/NavSearchContainer';
import { selectSearchFilterOptions } from '../../../app/Search/selectors/searchSelectors';
import { fetchTypeahead } from '../../../app/Search/utils/searchUtils';
import { SearchFilterContentTypes } from '../../../app/Shared/enums';

interface SearchBarProps {
  imagesUrl: string;
  searchOrigin: string;
}

const SearchBar = ({ imagesUrl, searchOrigin }: SearchBarProps) => {
  const dispatch = useDispatch<any>();

  const selectedSearchFilterOptions = useSelector(selectSearchFilterOptions);
  const { contentType = '' } = selectedSearchFilterOptions;

  return (
    <NavSearch
      contentType={contentType as SearchFilterContentTypes}
      onSubmit={(
        contentType: string,
        searchTerm: string,
        searchOrigin: string
      ) =>
        handleSubmit(
          contentType,
          searchTerm,
          searchOrigin,
          dispatch,
          selectedSearchFilterOptions
        )
      }
      userHasAnySubscription
      fetchTypeahead={fetchTypeahead}
      isEnterpriseMember
      pproPluginEnabled
      isSearchAppLoaded={isSearchAppLoaded}
      incrementTelemetry={Telemetry.increment}
      imagesUrl={imagesUrl}
      searchOrigin={searchOrigin}
    />
  );
};

Builder.registerComponent(SearchBar, {
  name: 'Search Bar',
  inputs: [
    {
      name: 'imagesUrl',
      type: 'string',
      required: true,
      defaultValue: __ASSETS_COMMON_IMAGES_URL__,
    },
    {
      name: 'searchOrigin',
      type: 'string',
      required: true,
      defaultValue: 'top_nav',
    },
  ],
});

export default SearchBar;
