import { Builder } from '@builder.io/react';

import SearchHero, {
  defaultContentTypes,
} from '../../../app/Search/components/SearchHero';
import { SearchFilterContentTypes } from '../../../app/Shared/enums';
import {
  callToActionInputs,
  secondaryCallToActionInputs,
} from '../elements/CallToAction.builder';

Builder.registerComponent(SearchHero, {
  name: 'Search Hero',
  inputs: [
    {
      name: 'headingFirstLine',
      type: 'string',
      required: false,
      defaultValue: 'Tell all your best stories of the',
    },
    {
      name: 'headingSecondLine',
      type: 'string',
      required: false,
      defaultValue: 'world. Lorem ipsum dolor sit',
    },
    {
      name: 'subheading',
      type: 'string',
      required: false,
      defaultValue: '',
    },
    {
      name: 'trendingTags',
      type: 'string',
      required: false,
      defaultValue: 'Documentary,Love,Sport',
      helperText: 'Comma-separated list of trending tags',
    },
    {
      name: 'videoSrc',
      type: 'file',
      allowedFileTypes: ['webm', 'mp4'],
      // https://www.builder.io/c/docs/custom-components-input-types#code-strong-file-strong-code
      required: false,
      defaultValue: '/home/video-homepage-hero-background.webm',
      helperText: 'Path of background video file',
    },
    {
      name: 'posterSrc',
      type: 'file',
      allowedFileTypes: ['jpg', 'png', 'webp'],
      required: false,
      defaultValue: '/home/video-homepage-hero-background.jpg',
      helperText: 'Path of background poster file',
    },
    {
      name: 'posterAlt',
      type: 'string',
      required: false,
      defaultValue: 'Background image',
    },
    {
      name: 'tall',
      type: 'boolean',
      required: false,
      helperText: 'Tall vs. short widget',
      defaultValue: true,
    },
    {
      name: 'alignment',
      type: 'string',
      required: false,
      defaultValue: 'left',
      enum: [
        {
          label: 'Left',
          value: 'left',
        },
        {
          label: 'Center',
          value: 'center',
        },
      ],
    },
    {
      name: 'contentTypes',
      type: 'list',
      required: false,
      helperText: 'List of clickable content types / options',
      defaultValue: defaultContentTypes,
      subFields: [
        {
          name: 'id',
          type: 'string',
          required: true,
          enum: Object.values(SearchFilterContentTypes),
          defaultValue: SearchFilterContentTypes.All_videos_content_type,
          helperText: 'URL root',
        },
        {
          name: 'label',
          type: 'string',
          required: true,
          defaultValue: '[Label]',
          helperText: 'Text label for content type',
        },
        {
          name: 'placeholderText',
          type: 'string',
          required: true,
          defaultValue: 'Search [CONTENT] library...',
          helperText: 'Search box placeholder text',
        },
      ],
    },
    ...callToActionInputs,
    ...secondaryCallToActionInputs,
  ],
});
