// BrowseLinkTrait.php
import _ from 'lodash';
import React, { ReactElement } from 'react';

import Logger from '../../../common/Logger';

export enum SearchOrigin {
  UNKNOWN = '(unknown)',
  HOME = 'home',
  TOP_NAV = 'top_nav',
  FILTERS = 'filters',
  SEARCH_BAR = 'search_bar',
  SORT_BY = 'sort_by',
  TYPE_AHEAD = 'type_ahead',
  STOCK_ITEM_CARD = 'stock_item_card',
  DETAILS_PAGE = 'details_page',
  RELATED_SEARCH = 'related-search',
  PAGINATION = 'pagination',
  ARTIST = 'artist',
  APPLIED_FILTERS = 'applied_filters',
  AUTOCORRECT = 'autocorrect',
}

export function linkWithOrigin(
  event: React.MouseEvent,
  url: string,
  searchOrigin = SearchOrigin.TOP_NAV
) {
  event.preventDefault();
  const appendChar = url.includes('?') ? '&' : '?';
  window.location.href = `${url}${appendChar}search-origin=${searchOrigin}`;
}

function telemetryCollectionAction(url: string) {
  const urlArr = (url || '').split('/');
  const slug = urlArr.length > 0 ? urlArr[urlArr.length - 1] : '';
  const telemetrySlug = `nav.clicked.collections.${slug}`;
  Logger.accessTelemetry().increment(telemetrySlug);
}

// e.g. BrowseLinkTrait.php
export const getBrowseLink = (
  url: string,
  children: React.ReactNode,
  className = '',
  id = ''
): ReactElement<HTMLLinkElement> => {
  const isSearchLink = /\/(video|audio|images)\/search/.test(url);
  // the below regex will only match specific collection pages, like /collections/authentic-collections
  // not the home collection page /collections
  const isCollectionLink =
    /\/(video|audio|images)\/collections\/[a-z0-9\-]+/.test(url);
  let onClick = _.noop;

  if (isSearchLink) {
    onClick = linkWithOrigin;
  } else if (isCollectionLink) {
    onClick = telemetryCollectionAction;
  }

  return (
    <a
      href={url}
      id={id}
      className={className}
      tabIndex={0}
      onClick={(evt) => onClick(evt, url)}
    >
      {children}
    </a>
  );
};

export const BrowseLink: React.FC<{
  url: string;
  className: string;
  id?: string;
}> = ({ url, children, className, id }) =>
  getBrowseLink(url, children, className, id);
