import { noop } from 'lodash';
import React, { useContext, useState } from 'react';
import cookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/search/OriginAreaEnum';
import { Telemetry } from '@videoblocks/kafka-rest-client';
import { FolderPlus } from '@videoblocks/react-icons';
import { Popper, Placements } from '@videoblocks/storywind';

import events from '../../Events/Events';
import StockItemAddToFolderEventFactory from '../../Events/StockItemAddToFolderEventFactory';
import { deleteFolderContent } from '../../Member/MemberBin/FolderActions';
import {
  addNewFolderWithStockItem,
  addStockItemsToFolder,
} from '../../Member/MemberBins/actions/FoldersActions';
import { selectMemberBins } from '../../Member/MemberBins/selectors/foldersSelectors';
import { STORYBOARD_REQUEST_ID_COOKIE } from '../../Storyboards/constants';
import FolderSelector from '../../app/FolderSelector/FolderSelector';
import { selectIsLoggedIn, selectIsMobile } from '../../auth/AuthSelectors';
import { StockDownloadContext } from '../context/StockDownloadContext';
import { StockItem } from '../types/StockItemTypes';
import { isStockItemInAnyFolder } from '../utils';
import SignUpForFoldersPopover from './Popover/SignUpForFoldersPopover';

interface Props {
  buttonClasses?: string;
  buttonText?: string;
  containPoppers?: boolean;
  onButtonClick?: () => void;
  onClickAction?: (e: MouseEvent) => void;
  stockItem: StockItem;
  stockItemFormats?: Record<string, unknown>[];
  telemetryKey: string;
  iconClassName?: string;
  folderCheckColor?: string;
  alternateIconInFolderColor?: string;
  alternateIconDefaultColor?: string;
  iconOnLeft?: boolean;
  openFoldersInNewWindow?: boolean;
  originArea?: OriginArea;
}

const FavoriteButton = ({
  buttonClasses = '',
  buttonText = '',
  containPoppers = false,
  onButtonClick = noop,
  onClickAction = noop,
  stockItem,
  stockItemFormats,
  telemetryKey,
  iconClassName = '',
  folderCheckColor = '#10ab5b',
  alternateIconDefaultColor = 'text-white',
  alternateIconInFolderColor = 'text-white',
  iconOnLeft = true,
  openFoldersInNewWindow = false,
  originArea = null,
}: Props) => {
  const inFolderAlternateIcon = (
    <svg
      className={twMerge(
        'action-icon stroke-current stroke-0 fill-current',
        iconClassName,
        alternateIconInFolderColor
      )}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M2.22,2.72a.52.52,0,0,1,.52-.52H7.49A.52.52,0,0,1,8,2.49L8.8,4.18a2.07,2.07,0,0,0,1.85,1.14H19.9a.51.51,0,0,1,.51.52h0v4.22a.27.27,0,0,0,.19.25,7.47,7.47,0,0,1,1.51.58.25.25,0,0,0,.34-.08v0a.24.24,0,0,0,0-.12V5.32a2.08,2.08,0,0,0-2.05-2.08H11A.5.5,0,0,1,10.52,3L9.67,1.27A2.08,2.08,0,0,0,7.81.12H2.22A2.09,2.09,0,0,0,.14,2.2V17.27a2.08,2.08,0,0,0,2.08,2.08H9.76A.26.26,0,0,0,10,19.1c0-.39,0-1.12.05-1.56a.27.27,0,0,0-.25-.27H2.74a.51.51,0,0,1-.52-.5h0Z" />
      <path d="M9.76,19.47H2.22A2.19,2.19,0,0,1,0,17.27V2.2A2.21,2.21,0,0,1,2.22,0H7.81a2.19,2.19,0,0,1,2,1.22l.85,1.68a.39.39,0,0,0,.35.22h9.43a2.2,2.2,0,0,1,2.17,2.2v5.35a.19.19,0,0,1,0,.15v0a.37.37,0,0,1-.52.14,7.81,7.81,0,0,0-1.48-.57.38.38,0,0,1-.28-.37V5.84a.4.4,0,0,0-.39-.4H10.65a2.19,2.19,0,0,1-2-1.21L7.85,2.54a.4.4,0,0,0-.35-.22H2.74a.4.4,0,0,0-.4.41v14a.4.4,0,0,0,.4.39H9.82a.39.39,0,0,1,.37.39c0,.45,0,1.17,0,1.56A.37.37,0,0,1,9.76,19.47ZM2.22.25a2,2,0,0,0-1.95,2V17.27a2,2,0,0,0,2,1.95H9.76a.12.12,0,0,0,.13-.12c0-.39,0-1.13,0-1.57a.13.13,0,0,0-.13-.13H2.73a.65.65,0,0,1-.64-.63v-14a.66.66,0,0,1,.65-.66H7.5a.63.63,0,0,1,.57.36l.84,1.69a1.94,1.94,0,0,0,1.74,1.07H19.9a.65.65,0,0,1,.64.65v4.22a.14.14,0,0,0,.1.13,7.68,7.68,0,0,1,1.53.59.13.13,0,0,0,.18,0h0s0,0,0,0V5.32a2,2,0,0,0-1.92-1.95H11A.68.68,0,0,1,10.4,3L9.56,1.33A1.93,1.93,0,0,0,7.81.25Z" />
      <path
        d="M17.75,11.52A6.25,6.25,0,1,0,24,17.77,6.25,6.25,0,0,0,17.75,11.52Zm3.15,5-2.79,3.73a1.48,1.48,0,0,1-1.06.57H17a1.49,1.49,0,0,1-1-.42L14.49,19a.72.72,0,0,1,.19-1,.71.71,0,0,1,.81,0l1.25,1.25a.25.25,0,0,0,.34,0h0l2.63-3.5a.71.71,0,1,1,1.14.85Z"
        // todo: set fill color on react-icon icon
        style={{ fill: `${folderCheckColor}` }}
      />
    </svg>
  );

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const bins = useSelector(selectMemberBins);
  const isMobile = useSelector(selectIsMobile);
  const { sceneRequestId: storyboardSceneRequestId } =
    useContext(StockDownloadContext);

  const [showFolderSelector, setShowFolderSelector] = useState(false);

  const handleClickFavoriteButton = (e) => {
    e.preventDefault();

    onButtonClick();

    setShowFolderSelector(!showFolderSelector);
    Telemetry.increment(`favorite.clicked.${telemetryKey}`);
  };

  const addStockItemToFolder = (bin) => {
    dispatch(
      // @ts-ignore
      addStockItemsToFolder(bin.uniqueId, stockItem, {
        stockItem,
        stockItemFormats,
      })
    );

    Telemetry.increment(`favorite.added.${telemetryKey}`);

    let storyboardRequestId: string;

    if (originArea === OriginArea.STORYBOARD) {
      storyboardRequestId = cookie.load(STORYBOARD_REQUEST_ID_COOKIE);
    }

    const addToFolderEvent = StockItemAddToFolderEventFactory.create(
      stockItem,
      originArea,
      bin.id,
      storyboardRequestId,
      storyboardSceneRequestId
    );

    events.produce(addToFolderEvent, addToFolderEvent.user.visitorCookieId);
  };

  const removeStockItemFromFolder = (bin) => {
    // @ts-ignore
    dispatch(deleteFolderContent(bin.uniqueId, [stockItem]));

    Telemetry.increment(`favorite.removed.${telemetryKey}`);
  };

  const handleFolderSelection = ({ bin, event }) => {
    const shouldRemove = bin.contentIds?.some(
      (existingContentId) => stockItem.contentId === existingContentId
    );

    if (shouldRemove) {
      removeStockItemFromFolder(bin);
    } else {
      addStockItemToFolder(bin);
    }

    onClickAction(event);
    setShowFolderSelector(false);
  };

  const handleClickCreateNewFolderButton = ({ event, name }) => {
    // @ts-ignore
    dispatch(addNewFolderWithStockItem(name, stockItem));
    onClickAction(event);
    setShowFolderSelector(false);
  };

  const getFolderIcon = () =>
    isStockItemInAnyFolder(bins, stockItem) ? (
      inFolderAlternateIcon
    ) : (
      <FolderPlus
        className={twMerge(
          'action-icon stroke-current stroke-0 fill-current',
          iconClassName,
          alternateIconDefaultColor
        )}
      />
    );

  const getButtonText = () => {
    if (!buttonText.length) return null;

    return (
      <span className={iconOnLeft ? 'ml-1' : 'mr-2'}>
        {isStockItemInAnyFolder(bins, stockItem) ? 'Added' : 'Add'} to Folder
      </span>
    );
  };

  const getPopperPlacement = () => {
    if (isMobile) {
      return Placements.Bottom; // Prevents poppers from escaping off-screen on mobile
    }
    return containPoppers ? Placements.Left : Placements.Right; // Right is preferable so user can see the stock item while organizing it
  };

  return (
    <Popper
      placement={getPopperPlacement()}
      strategy={containPoppers ? 'fixed' : 'absolute'} // absolute = based on container, fixed = based on viewport
      hideContent={() => setShowFolderSelector(false)}
      hideOnScroll={false}
      offset={[0, 12]}
      popperClasses="bg-white shadow-popover border border-gray-300 border-solid rounded"
      showArrow={isLoggedIn}
      showContent={showFolderSelector}
      triggerElement={(referenceElement) => (
        <button
          className={`favorite-button border-none cursor-pointer ${buttonClasses}`}
          onClick={handleClickFavoriteButton}
          ref={referenceElement}
          aria-label="Add to Folder"
        >
          <div className="flex-nowrap flex">
            {!iconOnLeft && getButtonText()}
            {getFolderIcon()}
            {iconOnLeft && getButtonText()}
          </div>
        </button>
      )}
    >
      {isLoggedIn ? (
        <FolderSelector
          folderClickedCallback={handleFolderSelection}
          createNewClickedCallback={handleClickCreateNewFolderButton}
          stockItem={stockItem}
          title="Find or Create Folder"
          openFoldersInNewWindow={openFoldersInNewWindow}
        />
      ) : (
        <SignUpForFoldersPopover contentId={stockItem.contentId} />
      )}
    </Popper>
  );
};

export default FavoriteButton;
