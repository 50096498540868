import { SiteEnum } from '../../../../common/SiteConstants/SiteConstants';
import { getCurrentSite } from '../../../../common/utils';
import { searchOrigins } from '../../../Navigation/constants';
import { ContentClass } from '../../../Shared/enums';
import { AnySearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import AudioSearchOptions from '../../entities/AudioSearchOptions';
import ImageSearchOptions from '../../entities/ImageSearchOptions';
import VideoSearchOptions from '../../entities/VideoSearchOptions';
import audioSearchOptionsToLocation from './audioSearchOptionsToLocation';
import imageSearchOptionsToLocation from './imageSearchOptionsToLocation';
import { NewSearchLocation } from './types';
import videoSearchOptionsToLocation from './videoSearchOptionsToLocation';

export function searchOptionsToLocation(
  options: Partial<AnySearchFilterOptions>
): NewSearchLocation {
  if (
    options.contentClass === ContentClass.Audio ||
    (!options.contentClass && getCurrentSite() === SiteEnum.Audioblocks)
  ) {
    return audioSearchOptionsToLocation(options);
  }

  if (
    options.contentClass === ContentClass.Image ||
    (!options.contentClass && getCurrentSite() === SiteEnum.Graphicstock)
  ) {
    return imageSearchOptionsToLocation(options);
  }

  return videoSearchOptionsToLocation(options);
}

export function resetSearchOptionsIfAssetIdSearch(
  options: Partial<AnySearchFilterOptions>
): Partial<AnySearchFilterOptions> {
  const assetIdContentClass = getContentClassByAssetId(options.searchTerm);
  if (!assetIdContentClass) {
    return options;
  }

  const newSearchOptions =
    assetIdContentClass === ContentClass.Video
      ? new VideoSearchOptions()
      : assetIdContentClass === ContentClass.Audio
      ? new AudioSearchOptions()
      : new ImageSearchOptions();

  return newSearchOptions.update({
    searchTerm: options.searchTerm,
    searchOrigin: options.searchOrigin || searchOrigins.SEARCH_BAR,
  });
}

/**
 * Get the content class that corresponds to the provided assetId.
 * @param assetId
 */
function getContentClassByAssetId(assetId: string): ContentClass {
  if (!assetId || !isAssetId(assetId)) {
    return null;
  }

  const contentInitial = assetId.substring(2, 3);
  switch (contentInitial) {
    case 'V':
    case 'v':
      return ContentClass.Video;
    case 'I':
    case 'i':
      return ContentClass.Image;
    case 'A':
    case 'a':
      return ContentClass.Audio;
  }
}

/**
 * Determine if the search term represents a stock item's assetId.
 * @param searchTerm
 */
function isAssetId(searchTerm: string): boolean {
  return /^SB[VIA][- ]\d{9,}$/i.test(searchTerm);
}
