import React from 'react';
import { useSelector } from 'react-redux';

import { selectNavMenuCategory } from '../../../ui/UiSelectors';
import { OtherCategoryTypes } from '../../../ui/UiTypes';

const ResourcesMenu = () => {
  const { categories } = useSelector(
    selectNavMenuCategory(OtherCategoryTypes.RESOURCES)
  );

  return (
    <div
      className="flex flex-col px-4 pt-6 pb-4 space-y-2 border-b border-gray-100 border-solid border-t-0 border-l-0 border-r-0"
      key="Resources"
    >
      <div
        role="heading"
        aria-level={2}
        className="text-gray-900 font-semibold text-xs tracking-widest text-left font-inter uppercase"
      >
        Resources
      </div>
      {Object.keys(categories)?.length && (
        <ul className="m-0 list-none pt-0 pb-none">
          {Object.keys(categories).map((title) => (
            <li key={title}>
              <a
                className="font-semibold leading-17 text-left font-inter text-gray-800 no-underline"
                href={categories[title].url}
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ResourcesMenu;
