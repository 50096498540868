import { useCallback, useEffect, useReducer, useState } from 'react';

import { ContentType } from '../../Shared/enums';

export interface RelatedVideoDatum {
  hasMore: boolean;
  page: number;
  isLoading?: boolean;
  results: any[];
}

export interface RelatedVideoData {
  sameShoot: RelatedVideoDatum;
  sameModel: RelatedVideoDatum;
  similarItems: RelatedVideoDatum;
}

const emptyDatum: RelatedVideoDatum = {
  hasMore: false,
  page: 0,
  isLoading: false,
  results: [],
};

const initialState = {
  sameShoot: emptyDatum,
  sameModel: emptyDatum,
  similarItems: emptyDatum,
} as const;

type Action =
  | { type: 'reset' }
  | { type: 'mergeFresh'; payload: { freshData: RelatedVideoData } }
  | { type: 'setIsLoading'; payload: { key: keyof RelatedVideoData } };

function reducer(data: RelatedVideoData, action: Action): RelatedVideoData {
  switch (action.type) {
    case 'reset':
      return initialState;

    case 'setIsLoading':
      return {
        ...data,
        [action.payload.key]: {
          ...data[action.payload.key],
          isLoading: true,
        },
      };

    case 'mergeFresh': {
      const keys: (keyof RelatedVideoData)[] = [
        'sameShoot',
        'sameModel',
        'similarItems',
      ];
      const { freshData } = action.payload;

      return keys.reduce((accumulated, key) => {
        const freshDatum = freshData[key];
        const existingDatum = data[key];

        if (freshDatum) {
          accumulated[key] = {
            hasMore: freshDatum.hasMore,
            page: freshDatum.page ?? 1,
            results: [...(existingDatum?.results || []), ...freshDatum.results],
            isLoading: false,
          };
        } else {
          accumulated[key] = existingDatum;
        }

        return accumulated;
      }, {} as RelatedVideoData);
    }

    default:
      throw new Error('Unknown action: ' + JSON.stringify(action));
  }
}

export default function useRelatedVideo(
  stockItemId: number,
  contentType: ContentType,
  pageSize?: number
): {
  isInitialized: boolean;
  isErrored: boolean;
  data: RelatedVideoData;
  loadMore(key: keyof RelatedVideoData): void;
  reinitialize(): void;
} {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const [data, dispatch] = useReducer(reducer, initialState);

  function loadMore(key: keyof RelatedVideoData) {
    const nextPage = data[key].page + 1;
    dispatch({ type: 'setIsLoading', payload: { key } });

    let url = `/api/video/${stockItemId}/related-video?onlyInclude=${key}&page=${nextPage}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }

    fetch(url).then(async (response) => {
      if (response.ok) {
        const freshData = await response.json();
        dispatch({ type: 'mergeFresh', payload: { freshData } });
      }
    });
  }

  const initialize = useCallback(() => {
    setIsInitialized(false);
    dispatch({ type: 'reset' });

    let url = `/api/video/${stockItemId}/related-video`;
    const searchParams = new URLSearchParams();
    if (contentType !== ContentType.Footage) {
      searchParams.set('onlyInclude', 'similarItems');
    }
    if (pageSize) {
      searchParams.set('pageSize', pageSize.toString());
    }
    const stringifiedSearchParams = searchParams.toString();
    if (stringifiedSearchParams) {
      url += `?${stringifiedSearchParams}`;
    }

    fetch(url)
      .then(async (response) => {
        if (response.ok) {
          const freshData = await response.json();
          dispatch({ type: 'mergeFresh', payload: { freshData } });
          setIsInitialized(true);
        } else {
          setIsErrored(true);
        }
      })
      .catch(() => {
        setIsErrored(true);
      });
  }, [stockItemId]);

  // Fetch initial data.
  useEffect(initialize, [stockItemId]);

  return { isInitialized, isErrored, data, loadMore, reinitialize: initialize };
}
