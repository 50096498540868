import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AUDIOBLOCKS,
  GRAPHICSTOCK,
  STORYBLOCKS,
  VIDEOBLOCKS,
} from '../../../../common/SiteConstants/SiteConstants';
import { getCurrentSite } from '../../../../common/utils';
import ModalDialog from '../../../Shared/ModalDialog';
import { toggleMobileFilterModalOpen } from '../../actions/SearchActions';
import { selectMobileFilterModalOpen } from '../../selectors/searchSelectors';
import MobileAudioFilters from './MobileAudioFilters';
import MobileImageFilters from './MobileImageFilters';
import MobileVideoFilters from './MobileVideoFilters';

const getSiteFilters = (setAppliedFiltersTotalCount): JSX.Element => {
  switch (getCurrentSite()) {
    case AUDIOBLOCKS:
      return (
        <MobileAudioFilters
          setAppliedFiltersTotalCount={setAppliedFiltersTotalCount}
        />
      );
    case GRAPHICSTOCK:
    case STORYBLOCKS:
      return (
        <MobileImageFilters
          setAppliedFiltersTotalCount={setAppliedFiltersTotalCount}
        />
      );
    case VIDEOBLOCKS:
      return (
        <MobileVideoFilters
          setAppliedFiltersTotalCount={setAppliedFiltersTotalCount}
        />
      );
  }
};

const MobileFiltersModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const mobileFilterModalOpen = useSelector(selectMobileFilterModalOpen);
  const [appliedFiltersTotalCount, setAppliedFiltersTotalCount] =
    React.useState(0);

  return (
    <ModalDialog
      isOpen={mobileFilterModalOpen}
      style={{
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }}
      parentSelector={() => document.querySelector('#search-results-app')}
      topOffsetClass="top-0"
      contentLabel={`Filter ${
        appliedFiltersTotalCount
          ? String.fromCharCode(183) + ' ' + appliedFiltersTotalCount
          : ''
      }`}
      onRequestClose={() => dispatch(toggleMobileFilterModalOpen(false))}
      className="p-0 rounded-none"
      closeButtonClassName="top-3.75vh w-max h-max"
      contentLabelClassName="m-0"
      contentLabelContainerClassName="mb-0 h-10vh pl-4 flex items-center"
    >
      <div className="h-9/10">
        {getSiteFilters(setAppliedFiltersTotalCount)}
      </div>
    </ModalDialog>
  );
};

export default MobileFiltersModal;
