import { partition } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { ChevronRight } from '@videoblocks/react-icons';

import BetaBadge from '../../../../common/components/BetaBadge';
import { titleCase } from '../../../../common/utils';
import { selectNavMenuCategory } from '../../../../ui/UiSelectors';

// individual link used in column
const CategoryLink = ({
  categoryName,
  categories,
  onClick,
  buttonClassName,
}: {
  categoryName: string;
  categories: object;
  onClick: (name: string) => void;
  buttonClassName?: string;
}) => {
  const { url = '', subCategories = [] } = categories[categoryName];
  const hasSubcategories = subCategories?.length > 0;
  const readableCategory = categoryName.replace(/-/g, ' ');

  const categoryLabelProps = {
    tabIndex: 0,
  };
  if (hasSubcategories) {
    Object.assign(categoryLabelProps, {
      role: 'button',
      onClick: (evt: MouseEvent) => {
        evt.stopPropagation();
        onClick(categoryName);
      },
    });
  } else {
    // link to category url if no subcategories
    Object.assign(categoryLabelProps, { href: url });
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={twMerge(
        'unifiedNav-browseLink lg:m-0 mt-3 lg:h-8 lg:pt-1 sm:pr-3 cursor-pointer hover:bg-blue-100 lg:pl-5 pr-5',
        buttonClassName
      )}
      key={categoryName}
      onKeyDown={(evt) =>
        (evt.currentTarget.firstChild as HTMLLinkElement).click()
      }
      onClick={(evt) =>
        (evt.currentTarget.firstChild as HTMLLinkElement).click()
      }
    >
      <a
        {...categoryLabelProps}
        className="flex flex-row text-gray-900 font-normal"
      >
        {/* don't titleCase labels w/2+ sequential uppercase letters (i.e. 360 / VR) */}
        <span className="float-left flex-grow">
          {categories[categoryName]?.title ||
            (/[A-Z]{2,}/.test(categoryName)
              ? readableCategory
              : titleCase(readableCategory))}
          {categories[categoryName]?.isBeta && <BetaBadge className="mx-2" />}
        </span>
        {hasSubcategories && (
          <span className="float-right pt-0.5">
            <ChevronRight className="fill-current w-3 h-3" />
          </span>
        )}
      </a>
    </div>
  );
};

type Props = {
  containerProps?: Partial<{ className: string; style: object }>;
  menuName: string;
  buttonClassName?: string;
  onClick?: (string) => void;
  exclude?: string[];
};

// category links as individual columns
export const CategoryLinkColumns = ({
  menuName,
  onClick,
  buttonClassName,
  exclude,
}: Omit<Props, 'containerProps'>) => {
  const { categories = {} } = useSelector(selectNavMenuCategory(menuName));

  // partition out categories with new features
  const [categoriesWithNewFeatures, filteredCategories] = partition(
    Object.keys(categories),
    (categoryName) => categories[categoryName]?.newFeature
  );

  // move child-less categories second / sort alphabetically within groups
  const [categoriesWithChildren, categoriesWithoutChildren] = partition(
    filteredCategories,
    (categoryName) => categories[categoryName]?.subCategories?.length > 0
  );
  const categoryNames = [
    ...categoriesWithNewFeatures.sort(),
    ...categoriesWithChildren.sort(),
    ...categoriesWithoutChildren.sort(),
  ].filter((categoryName) => !exclude?.includes(categoryName));

  return (
    <>
      {categoryNames.map((categoryName) => (
        <CategoryLink
          categoryName={categoryName}
          categories={categories}
          onClick={onClick}
          key={categoryName}
          buttonClassName={buttonClassName}
        />
      ))}
    </>
  );
};

export const MobileCreativeToolsLinksList = (props: Props) => (
  <div
    id="categoryLinksList"
    className={twMerge(
      props.containerProps?.className,
      'subNav-subCategory lg:px-4 grid leading-normal'
    )}
    style={props.containerProps?.style}
  >
    <CategoryLinkColumns {...props} exclude={['maker-for-teams']} />
  </div>
);

// category link columns wrapped in container
const CategoryLinksList = (props: Props) => (
  <div
    id="categoryLinksList"
    className={twMerge(
      props.containerProps?.className,
      'subNav-subCategory lg:px-4 grid md:grid-cols-2 leading-normal md:grid-flow-col md:grid-rows-10'
    )}
    style={props.containerProps?.style}
  >
    <CategoryLinkColumns {...props} />
  </div>
);

export default CategoryLinksList;
