export type User = {
  id: number;
  email: string;
  displayName: string;
};

export enum PlanClassification {
  LEGACY_PLAN_CLASSIFICATION = 'Legacy',
  BASIC_PLAN_CLASSIFICATION = 'Basic',
  STARTER_PLAN_CLASSIFICATION = 'Starter',
  BUNDLE_PLAN_CLASSIFICATION = 'Bundle',
  UNLIMITED_PLAN_CLASSIFICATION = 'Unlimited',
  ENTERPRISE_PLAN_CLASSIFICATION = 'Enterprise',
  GROWTH_PLAN_CLASSIFICATION = 'Growth',
  PRO_PLAN_CLASSIFICATION = 'Pro',
}

// UserSubscription.php
export type Subscription = {
  id: number;
  uniqueId: string;
  planId: number;
  organizationId: number;
  siteId: number;
  fullName: string;
  status: string;
  subscriptionEndDate: object;
  daysLeftOnSubscription: number;
  dateAdded: string;
  dateUpdated: string;
  isStarterBundle: boolean;
  isMakerOnly: boolean;
  isUnlimitedBundle: boolean;
  isGrowthBundle: boolean;
  isPremierePro: boolean;
  planClassification: PlanClassification;
  rolloverPlanId?: number;
  monthlyDownloadsRemaining?: number;
};

export interface AuthReducerState {
  recaptchaToken?: string;
  userFullName: string;
  makerHooksVisible?: boolean;
  projects?: [];
  isMobile?: boolean;
  monthlyDownloadsRemaining?: number;
  isLoggedIn: boolean;
  isIpAuth: boolean;
  user: User;
  subscription: Subscription;
  primarySubscription: Subscription;
  videoSubscription: Subscription;
  audioSubscription: Subscription;
  imageSubscription: Subscription;
  permissions: { [key: string]: boolean };
  featureFlags: { [key: string]: boolean };
}
