import React, { Component } from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { Maker } from '@videoblocks/react-icons';
import { Popper, Placements } from '@videoblocks/storywind';

import { selectIsLoggedIn } from '../../../auth/AuthSelectors';
import SignUpForFeaturePopover from './SignUpForFeaturePopover';

type Props = {
  mediaType: string;
  dismissHandler?: () => void;
  stockItemId: number;
  userIsLoggedIn?: boolean;
  showSignupEnticement?: () => void;
  hideSignupEnticement?: () => void;
  showTooltip?: () => void;
  hideTooltip?: () => void;
  className?: string;
  iconClassName?: string;
  onClickAction: () => void;
  stayVisible?: boolean;
  disableTooltip?: boolean;
  placement?: Placements;
};

type State = {
  shouldShowMakerSignupEnticement: boolean;
  shouldShowMakerTooltip: boolean;
};

class SignUpForMakerPopover extends Component<Props, State> {
  isMounted = false;

  static defaultProps = {
    dismissHandler: () => {
      return undefined;
    },
    onClickAction: null,
    stayVisible: false,
    disableTooltip: false,
  };

  state = {
    shouldShowMakerSignupEnticement: false,
    shouldShowMakerTooltip: false,
  };

  constructor(props) {
    super(props);
    this.isMounted = false;
  }

  componentDidMount = () => {
    this.isMounted = true;
  };

  componentWillUnmount = () => {
    this.isMounted = false;
  };

  buildUrl(base, stockItemId) {
    return base + '?' + this.props.mediaType + 'Ids=' + stockItemId;
  }

  render() {
    const {
      mediaType,
      dismissHandler,
      stockItemId,
      userIsLoggedIn,
      iconClassName = '',
      stayVisible,
      children,
      className,
      disableTooltip,
    } = this.props;

    const { shouldShowMakerSignupEnticement, shouldShowMakerTooltip } =
      this.state;

    const onButtonClick = () => {
      Telemetry.increment('maker.search.signup.click');
    };

    const onClick = () => {
      this.props.onClickAction?.();

      if (userIsLoggedIn) {
        Telemetry.increment('maker.search.precreate.click');
        window.open(
          this.buildUrl(__MAKER_PRECREATE_URL__, stockItemId),
          '_blank'
        );
      } else {
        Telemetry.increment('maker.search.presignup.click');
        this.isMounted &&
          this.setState({ shouldShowMakerSignupEnticement: true });
      }
    };

    const onHover = () => {
      Telemetry.increment('maker.search.action.hover');
      this.isMounted && this.setState({ shouldShowMakerTooltip: true });
    };

    const hideAll = () => {
      this.isMounted &&
        this.setState({
          shouldShowMakerSignupEnticement: false,
          shouldShowMakerTooltip: false,
        });
    };

    return (
      <div className={`${stayVisible ? 'block' : 'lg:block hidden'}`}>
        <Popper
          placement={
            this.props.placement ? this.props.placement : Placements.Left
          }
          popperClasses={
            shouldShowMakerSignupEnticement
              ? ''
              : 'px-2 py-1 text-white bg-black rounded opacity-90 text-sm'
          }
          showContent={
            shouldShowMakerSignupEnticement ||
            (shouldShowMakerTooltip && !disableTooltip)
          }
          hideContent={hideAll}
          showArrow={!shouldShowMakerSignupEnticement}
          triggerElement={(referenceElement) => (
            <div
              role="img"
              title="Edit with Maker"
              className={twMerge(
                'action-wrapper maker-button button-sizing action-maker cursor-pointer flex justify-end items-center',
                mediaType === 'audio'
                  ? 'stockItemCard-makerButton pb-1'
                  : 'stockItemCard-makerButton action-icon h-8 px-1 py-2',
                className
              )}
              ref={referenceElement}
              onMouseEnter={onHover}
              onMouseLeave={() =>
                this.isMounted &&
                this.setState({ shouldShowMakerTooltip: false })
              }
              onClick={onClick}
              onKeyDown={onClick}
            >
              {children}
              <Maker className={twMerge('self-center', iconClassName)} />
            </div>
          )}
        >
          {shouldShowMakerSignupEnticement ? (
            <SignUpForFeaturePopover
              postSignUpUrl={this.buildUrl(__MAKER_SIGNUP_PATH__, stockItemId)}
              dismissHandler={dismissHandler}
              tagLine="Edit this item using Maker, our simple video editor"
              type="maker"
              buttonText="Signup for free to edit"
              onButtonClick={onButtonClick}
              loginRedirect={
                '/login?redirect=' +
                this.buildUrl(__MAKER_PRECREATE_URL__, stockItemId)
              }
            />
          ) : (
            <div id="tooltip-maker" className="whitespace-nowrap">
              {children || <>Edit with Maker</>}
            </div>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userIsLoggedIn: selectIsLoggedIn(state),
});

export default connect(mapStateToProps)(SignUpForMakerPopover);
