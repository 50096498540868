import queryString from 'query-string';

import { SearchFilterContentTypes } from '../../../Shared/enums';
import {
  VideoQualityType,
  VideoSelectedSearchFilterOptions,
} from '../../containers/MenuContainerInterfaces';
import { UsageRights } from '../../entities/UsageRights';
import {
  categoryTypes,
  templateTypes,
} from '../../entities/VideoSearchFilterOptions';
import sharedSearchOptionsToQueryParams from './sharedSearchOptionsToQueryParams';
import { NewSearchLocation } from './types';

const templateFilterTypes = [
  SearchFilterContentTypes.Templates,
  SearchFilterContentTypes.Premiere_pro_templates,
  SearchFilterContentTypes.After_effects,
  SearchFilterContentTypes.Davinci_resolve,
  SearchFilterContentTypes.Apple_motion,
];

export default function videoSearchOptionsToLocation(
  options: Partial<VideoSelectedSearchFilterOptions>
): NewSearchLocation {
  let pathname = '';
  const queryParams = sharedSearchOptionsToQueryParams(options) as any;

  switch (options.contentType) {
    case SearchFilterContentTypes.Footage:
      pathname += '/video';
      break;
    case SearchFilterContentTypes.Motion_bgs:
      pathname += '/motion-graphics';
      break;
    case SearchFilterContentTypes.Templates:
    case SearchFilterContentTypes.Premiere_pro_templates:
    case SearchFilterContentTypes.After_effects:
    case SearchFilterContentTypes.Davinci_resolve:
    case SearchFilterContentTypes.Apple_motion:
      pathname += '/templates';
      break;
    default:
      pathname += '/all-video';
  }

  const templateType =
    templateFilterTypes.includes(options.contentType) && options.templateType
      ? templateTypes.find(({ value }) => value === options.templateType)
      : null;

  const category = options.categories
    ? options.categories
        .split(',')
        .map((category) =>
          categoryTypes.find(({ value }) => category === value)
        )
        .filter(Boolean)[0]
    : null;
  const categoryUrlId = category?.urlId;

  if (templateFilterTypes.includes(options.contentType) && templateType) {
    pathname += `/${templateType.urlId}`;

    if (categoryUrlId) {
      queryParams.categories = categoryUrlId;
    }
  } else if (categoryUrlId) {
    pathname += `/${categoryUrlId}`;
  }

  if (options.searchTerm) {
    pathname += `/search/${encodeURIComponent(options.searchTerm)}`;
  } else if (!templateType && !category) {
    pathname += '/search';
  }

  if (options.videoQuality && options.videoQuality !== VideoQualityType.HD) {
    queryParams.video_quality = options.videoQuality;
  }

  if (options.talentReleased) {
    queryParams.has_talent_released = options.talentReleased;
  }

  if (options.propertyReleased) {
    queryParams.has_property_released = options.propertyReleased;
  }

  if (options.usageRights && options.usageRights !== UsageRights.All) {
    queryParams.usage_rights = options.usageRights;
  }

  if (options.minDuration) {
    queryParams.min_duration = options.minDuration;
  }

  if (options.maxDuration) {
    queryParams.max_duration = options.maxDuration;
  }

  if (options.frameRates?.length > 0) {
    queryParams.frame_rates = options.frameRates;
  }

  const query = queryString.stringify(queryParams, {
    arrayFormat: 'index',
    skipNull: true,
    skipEmptyString: true,
  });

  return { url: `${pathname}${query ? `?${query}` : ''}` };
}
