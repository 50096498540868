export enum SearchFilterContentTypes {
    All_videos_content_type = 'all-videos',
    All_audio_content_type = 'all-audio',
    All_images_content_type = 'all-images',
    Footage = 'footage',
    Motion_bgs = 'motion-backgrounds',
    Templates = 'templates',
    Premiere_pro_templates = 'premiere-pro-templates',
    After_effects = 'after-effects-templates',
    Davinci_resolve = 'davinci-resolve-templates',
    Apple_motion = 'apple-motion-templates',
    Music = 'music',
    Sound_effects = 'sfx',
    Photos = 'photos',
    Snapshots = 'snapshots',
    Vectors = 'vectors',
    Illustrations = 'illustrations',
}

export type SearchOption = {
    id: SearchFilterContentTypes;
    label: string;
    placeholderText: string;
    videoSrc?: string;
    posterSrc?: string;
};
